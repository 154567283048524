// Standard library imports
import React, { createRef, useEffect, useRef, useState } from 'react';

// External library imports
import { useField } from 'formik';
import 'react-dropdown/style.css';
import { get as LodashGet } from 'lodash';
import moment, { isMoment } from 'moment';
import Select, { components } from 'react-select';
import Datetime from 'react-datetime';

// Internal module imports
import { Capitalize } from '../../utils/stringHelper';
import classes from './Input.module.css';
import { Multiselect } from 'multiselect-react-dropdown';
import CalendarIcon from '../../assets/calendar.svg';
import Searchable from 'react-select/creatable';
import Checkbox from '../Inputs/Checkbox/Checkbox';
import dropdownClasses from '../Inputs/Dropdown/index.module.css';

export const Input = ({ label, leftIcon, rightIcon, disabled, ...props }) => {
    const [field, meta] = useField(props);
    const leftIconClickHandler = props.leftIconClickHandler || (() => {});
    const rightIconClickHandler = props.rightIconClickHandler || (() => {});

    useEffect(() => {
        if (props.defaultValue) props.setFieldValue(props.name, props.defaultValue);
    }, []);

    return (
        <div className={`${classes.InputContainer} ${disabled ? classes.InputContainerDisable : ''}`}>
            <div className={classes.InputIconWrapper}>
                {leftIcon && (
                    <div className={classes.LeftIconWrapper} onClick={leftIconClickHandler}>
                        {leftIcon}
                    </div>
                )}
                {rightIcon && (
                    <div className={classes.RightIconWrapper} onClick={rightIconClickHandler}>
                        {rightIcon}
                    </div>
                )}
                <input
                    disabled={disabled}
                    className={classes.InputPlaceholder}
                    type="text"
                    {...field}
                    {...props}
                    required={false}
                    placeholder={label}
                    style={props.size === 'large' ? { height: '3vw', ...props.style } : { ...props.style }}
                    onWheel={(e) => e.target.blur()} // to disable scroll input value change
                />
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export const InputWithOutState = ({ label, ...props }) => {
    return (
        <div className={classes.InputContainer} style={props.style ? props.style : {}}>
            <div>
                <input
                    {...props}
                    placeholder={label}
                    onWheel={(e) => e.target.blur()} // to disable scroll input value change
                />
            </div>
        </div>
    );
};

export const InputFile = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    useEffect(() => {
        if (props.defaultValue) props.setFieldValue(props.name, props.defaultValue);
    }, []);
    return (
        <div className={classes.InputContainer}>
            <div>
                <input type="file" {...field} {...props} required={false} placeholder={label} className={classes.FileInput} />
                {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
            </div>
        </div>
    );
};

// export const DropdownComponent = ({
//   label,
//   disabled = false,
//   defaultValue,
//   onChange = null,
//   ...props
// }) => {
//   const meta = props.getFieldMeta(props.name);
//   const [value, setValue] = useState(
//     defaultValue && defaultValue[0] ? defaultValue[0] : props.values[0]
//   );
//   useEffect(() => {
//     const initialValue =
//       defaultValue && defaultValue[0] ? defaultValue[0] : value;
//     props.setFieldValue(props.name, initialValue?.value);
//     setValue(initialValue);
//   }, [JSON.stringify(defaultValue)]);

//   const handleDropdownChange = (e) => {
//     props.setFieldValue(props.name, e.value);
//     setValue(e);
//     onChange && onChange(e);
//   };

//   return (
//     <div className={classes.InputContainer}>
//       <div className={classes.Dropdown}>
//         <Dropdown
//           disabled={disabled}
//           value={value}
//           options={props.values}
//           onChange={handleDropdownChange}
//           placeholder=""
//           onFocus={() => props.setFieldTouched(props.name, true)}
//         />
//         {meta.touched && meta.error ? (
//           <div className="error-msg">{meta.error}</div>
//         ) : null}
//       </div>
//     </div>
//   );
// };
export const SearchableDropdownComponent = ({ label, disabled = false, defaultValue, ...props }) => {
    const meta = props.getFieldMeta(props.name);
    const [value, setValue] = useState(defaultValue && defaultValue[0] ? defaultValue[0] : props.values[0]);
    useEffect(() => {
        const initialValue = defaultValue && defaultValue[0] ? defaultValue[0] : value.value;
        props.setFieldValue(props.name, initialValue);
    }, []);

    const handleDropdownChange = (e) => {
        props.setFieldValue(props.name, e.value);
        setValue(e);
    };

    return (
        <div className={classes.InputContainer}>
            <div className={classes.Dropdown}>
                <Searchable
                    isDisabled={disabled}
                    value={value}
                    options={props.values}
                    onChange={handleDropdownChange}
                    placeholder=""
                    onFocus={() => props.setFieldTouched(props.name, true)}
                />
                {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
            </div>
        </div>
    );
};
export const MultiSelectDropdown = ({ label, defaultValues, ...props }) => {
    const dropdownRef = createRef('');

    const options = [
        { name: 'Sun', value: 'sunday' },
        { name: 'Mon', value: 'monday' },
        { name: 'Tue', value: 'tuesday' },
        { name: 'Wed', value: 'wednesday' },
        { name: 'Thu', value: 'thursday' },
        { name: 'Fri', value: 'friday' },
        { name: 'Sat', value: 'saturday' },
    ];

    const selectedValues = (props.values || options).filter((option) => defaultValues?.includes(option.value));

    const meta = props.getFieldMeta(props.name);

    const handleChange = () => {
        const ele = dropdownRef.current.getSelectedItems();
        let selectedDays = ele.map((option) => option.value);
        let processedValues = {};
        let curOptions = props.values || options;
        curOptions.map((option) => (processedValues[option.value] = selectedDays.includes(option.value)));
        props.setFieldValue(props.name, processedValues);
    };

    return (
        <div className={classes.InputContainer}>
            <div className={classes.Dropdown}>
                <Multiselect
                    selectedValues={selectedValues}
                    ref={dropdownRef}
                    showCheckbox
                    closeOnSelect={false}
                    options={props.values || options}
                    displayValue="name"
                    placeholder={label}
                    onSelect={handleChange}
                    onRemove={handleChange}
                    onFocus={() => props.setFieldTouched(props.name, true)}
                    emptyRecordMsg="No Options"
                />
                {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
            </div>
        </div>
    );
};

export const TextArea = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    return (
        <div className={classes.InputContainer}>
            <div className={classes.TextArea}>
                <textarea {...field} {...props} required={false} />
                {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
            </div>
        </div>
    );
};

export const Password = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    const [type, setType] = useState('password');

    return (
        <div className={classes.InputContainer}>
            <div className={classes.Label}>
                {Capitalize(label)} {props.required ? <span style={{ color: 'red' }}>*</span> : ''}
            </div>
            <div className={classes.PasswordContainer}>
                <input type={type} {...field} {...props} required={false} />
                {type === 'password' ? (
                    <i class="fa fa-eye" aria-hidden="true" style={{ fontSize: '1vw', color: 'darkgrey' }} onClick={() => setType('text')} />
                ) : (
                    <i class="fa fa-eye-slash" aria-hidden="true" style={{ fontSize: '1vw', color: 'darkgrey' }} onClick={() => setType('password')} />
                )}
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export const RadioButtons = ({ label, setFieldValue, ...props }) => {
    const [field, meta] = useField(props);
    useEffect(() => {
        if (!LodashGet(props.values, props.name)) setFieldValue(props.name, props.options[0].value);
    }, []);
    return (
        <div className={classes.RadioButtons}>
            {props.options.map((option, i) => (
                <div class={classes.radioItem}>
                    <input
                        type="radio"
                        {...field}
                        id={option.value}
                        value={option.value}
                        checked={LodashGet(props.values, props.name) ? LodashGet(props.values, props.name) === option.value : i === 0}
                    />
                    <label for={option.value}>{option.label}</label>
                </div>
            ))}
        </div>
    );
};

export const ToggleButton = ({ label, ...props }) => {
    const [field, meta] = useField(props);
    useEffect(() => {
        if (props.defaultValue) props.setFieldValue(props.name, props.defaultValue);
    }, []);
    return (
        <div>
            <div className={classes.ToggleButton} style={props.style ? props.style : {}}>
                <label class={classes.Switch}>
                    <input type="checkbox" {...field} {...props} checked={LodashGet(props.values, props.name) || props.checked} />
                    <span class={classes.Slider}></span>
                </label>
                <span>{Capitalize(label)}</span>
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export const ToggleButtonWithState = ({ value, onChange, disabled = false, label = '', labelPosition = 'right', defaultStyle = false, style = {}, sliderStyle = {} }) => {
    return (
        <div className={classes.ToggleButton} style={defaultStyle ? { marginTop: '0', gridColumnGap: '0' } : style}>
            {labelPosition === 'left' && <span>{Capitalize(label)}</span>}
            <label class={classes.Switch}>
                <input type="checkbox" onChange={onChange} disabled={disabled} checked={value || false} />
                <span class={classes.Slider} style={sliderStyle}></span>
            </label>
            {labelPosition === 'right' && <span>{Capitalize(label)}</span>}
        </div>
    );
};

export const CheckboxComponent = ({ label, type = '', ...props }) => {
    // eslint-disable-next-line
    const [field, meta] = useField(props);
    return (
        <div>
            <div className={classes.CheckboxContainer}>
                {props.options.map((option, i) => (
                    <label className={'checkbox-container checkbox-container-blue'}>
                        {option.label}
                        <input type="checkbox" {...field} id={option.value} value={option.value} />
                        <span class="checkmark"></span>
                    </label>
                ))}
            </div>
            {meta.touched && meta.error ? <div className="error-msg">{meta.error}</div> : null}
        </div>
    );
};

export const DateTimePicker = ({ label, defaultValue, ...props }) => {
    const [field, meta] = useField(props);
    const [value, setValue] = useState(isMoment(defaultValue) ? defaultValue : moment(defaultValue));

    useEffect(() => {
        if (!defaultValue) props.setFieldValue(props.name, moment(value).format());
        else props.setFieldValue(props.name, moment(defaultValue).format());
    }, []);

    const handleDateChange = (val) => {
        const value = moment(val).format();
        props.setFieldValue(props.name, value);
        setValue(value);
    };

    return (
        <div className={classes.InputContainer + ' Input'}>
            <div className={classes.Dropdown}>
                <label htmlFor={props.name}>
                    {label} {props.required ? <span className="red">*</span> : null}
                </label>
                <div className={classes.DateContainer}>
                    <Datetime
                        dateFormat="DD/MM/yyyy"
                        onOpen={() => props.setFieldTouched(props.name, true)}
                        initialValue={value}
                        closeOnSelect={true}
                        onChange={(val) => handleDateChange(val)}
                        {...props}
                    />
                    <img src={CalendarIcon} alt="" />
                </div>
                {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
            </div>
        </div>
    );
};

export const DropdownComponent = ({ disabled, leftIcon, defaultValue = {}, options = [], ...props }) => {
    const CustomOption = ({ children, isSelected, ...rest }) => {
        return (
            <components.Option isSelected={isSelected} {...rest}>
                <div className={dropdownClasses.ContentWrapper}>
                    {children}
                    <Checkbox checked={isSelected} />
                </div>
            </components.Option>
        );
    };

    const getControlStyle = (base) => ({
        ...base,
        background: 'var(--gray-50) 0% 0% no-repeat padding-box !important',
        borderRadius: '8px',
        border: '1px solid #DEE4EB',
        boxShadow: 'none',
        '&:hover': {
            border: '1px solid #DEE4EB',
        },
    });

    const getValueContainerStyle = (base) => ({
        ...base,
        padding: 0,
    });

    const getInputStyle = (base, size, leftIcon) => ({
        ...base,
        fontSize: '0.95vw',
        height: size === 'large' ? '3.2vw' : '2.5vw',
        padding: leftIcon ? '0.5vw 2.2vw' : '0.5vw 0.521vw',
        margin: 0,
    });

    const getSignleValue = (base, leftIcon) => ({
        ...base,
        fontSize: '0.95vw',
        padding: leftIcon ? '0 2.2vw' : '0 0.521vw',
    });

    const getPlaceholderStyle = (base) => ({
        ...base,
        fontSize: '0.95vw',
        padding: '0 2.2vw',
        color: '#6A7682',
    });

    const getMenuListStyle = (base) => ({
        ...base,
        padding: 0,
        borderRadius: '8px',
    });

    const getOptionStyle = (base, leftIcon, { isSelected, isFocused }) => ({
        ...base,
        fontSize: '0.95vw',
        color: '#000000',
        backgroundColor: isSelected || isFocused ? 'var(--color-secondary)' : undefined,
        padding: leftIcon ? '0.5vw 2.2vw' : '0.5vw 0.521vw',
        ':active': {
            backgroundColor: 'var(--color-secondary)',
        },
    });

    const [values, setValues] = useState(null);

    useEffect(() => {
        if (defaultValue && defaultValue.value !== undefined) {
            const selected = options.find((op) => op.value === defaultValue.value);
            selected && onChange(selected);
        }
    }, [JSON.stringify(defaultValue), JSON.stringify(options)]);

    const onChange = (e) => {
        setValues(e);
        props.onChange && props.onChange(e);
    };

    return (
        <div style={disabled ? { opacity: '0.5' } : {}}>
            <div className={dropdownClasses.InputIconWrapper} style={props.width ? { width: props.width } : {}}>
                {leftIcon && <div className={dropdownClasses.LeftIconWrapper}>{leftIcon}</div>}
                <Select
                    isDisabled={disabled}
                    value={values}
                    options={options}
                    components={{
                        Option: CustomOption,
                    }}
                    styles={{
                        control: getControlStyle,
                        valueContainer: getValueContainerStyle,
                        input: (base) => getInputStyle(base, props.size, leftIcon),
                        singleValue: (base) => getSignleValue(base, leftIcon),
                        placeholder: getPlaceholderStyle,
                        menuList: getMenuListStyle,
                        option: (base, rest) => getOptionStyle(base, leftIcon, rest),
                    }}
                    onChange={onChange}
                />
            </div>
        </div>
    );
};
