// Standard library imports
import React, { useState, useEffect } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilImport } from '@iconscout/react-unicons';
import moment from 'moment';

// Internal module imports
import AuditClasses from './index.module.css';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import classes from '../../../../styles/AllDevices.module.css';
import CustomTimePicker from '../../../../components/Inputs/TimePicker/TimePicker';
import { useLoader } from '../../../../hooks';
import { regulationsService } from '../../../../services/regulationsService';
import { RootAuditPQToolService } from '../../../../services/RootAuditPQToolsService';
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';

const LIMIT = 15;

const AuditDownloader = ({ queryParamsData, updateQueryParams }) => {
    const curCetDateTime = moment().tz('Europe/Berlin');
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(queryParamsData.date ? queryParamsData.date : new Date(curCetDateTime.format()));
    const [endTime, setEndTime] = useState(queryParamsData.endTime ? queryParamsData.endTime : curCetDateTime.format('HH:mm:ss'));
    const [startTime, setStartTime] = useState(queryParamsData.startTime ? queryParamsData.startTime : curCetDateTime.subtract(15, 'minutes').format('HH:mm:ss'));
    const [marketOptions, setMarketOption] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState({});
    const [tableData, setTableData] = useState([]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        updateQueryParams('date', date);
    }, [date]);

    useEffect(() => {
        getMarkets();
    }, []);

    useEffect(() => {
        if (selectedMarket?.value) {
            getEdges();
        }
    }, [selectedMarket, skip]);

    const getMarkets = () => {
        regulationsService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            label: item.name,
            value: item.name,
            bidCollection: item.bidCollection,
        }));
        setMarketOption(temp);
        setSelectedMarket(queryParamsData.market ? JSON.parse(queryParamsData.market) : temp[0]);
    };

    const getEdges = () => {
        const params = {
            market: selectedMarket.value,
            date: date,
            bidCollection: selectedMarket.bidCollection,
            skip: skip,
            limit: LIMIT,
        };
        RootAuditPQToolService.readAll(params, startLoader, handleEdgeSuccess, handleError, stopLoader);
    };

    const handleEdgeSuccess = ({ data }) => {
        const proccessedData = data.data?.[0]?.data.map((e) => ({
            edge: e.edgeAlias,
            customer: e.customer[0].name,
            instance: e.instance[0].name,
            action: (
                <CustomTooltip content={'Energinet Audit'}>
                    <div onClick={() => energinetDownload(e)}>
                        <UilImport
                            size="1.2vw"
                            style={{
                                cursor: 'pointer',
                                color: 'var(--color-primary)',
                            }}
                        />
                    </div>
                </CustomTooltip>
            ),
        }));
        setTableData(proccessedData);
        setTotalCount(data.data?.[0]?.totalCount?.[0]?.total);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    const energinetDownload = (data) => {
        const params = {
            signalId: data.properties.signalId,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            start: startTime,
            end: endTime,
            type: 'energinetAudit',
        };
        signalsServiceForRoot.downLoadEnergynetAudit(params, startLoader, handleDownloadEnergynetAudit, handleError, stopLoader);
    };

    const handleDownloadEnergynetAudit = ({ data }) => {
        let formatedData = [];
        let formatedSimulatedData = [];
        data.data.data.map((e) => {
            let temp = {
                date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                time: momentTimeFormater(e.time).format('HH:mm:ss'),
                frequency: e.frequency,
                capacitySold: e.soldCapacity,
                targetCapacity: e.targetCapacity,
                '-5%': e.minusFivePercent,
                load: e.load,
                '+20%': e.plusTwentyPercent,
                ...(!data.data?.basePower && {
                    deltaLoad: e.deltaLoad,
                    status: e.status,
                }),
            };
            if (data.data?.basePower) {
                temp.basePower = e.basePower;
                temp.deltaLoad = e.deltaLoad;
                temp.status = e.status;
            }
            formatedData.push(temp);
            if (data.data?.isSimulatedPower) {
                let simulatedTemp = {
                    date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                    time: momentTimeFormater(e.time).format('HH:mm:ss'),
                    frequency: e.frequency,
                    capacitySold: e.soldCapacity,
                    targetCapacity: e.simulatedTargetCapacity,
                    '-5%': e.simulatedMinusFivePercent,
                    load: e.simulatedLoad,
                    '+20%': e.simulatedPlusTwentyPercent,
                    deltaLoad: e.deltaLoad,
                    status: e.simulatedStatus,
                };
                formatedSimulatedData.push(simulatedTemp);
            }
        });

        let headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Capacity Sold', 'Target Capacity', '-5%', 'Load', '+20% '];

        if (data.data?.basePower) {
            headers.push(...['Base Power', 'Delta Load', 'Status']);
        } else {
            headers.push(...['Delta Load', 'Status']);
        }

        data.data.data.length && DownloadAsExcel(formatedData, `${data.data?.instanceName}`, headers);
        if (formatedSimulatedData.length) {
            DownloadAsExcel(formatedSimulatedData, `${data.data?.instanceName} Simulated Power`, [
                'Date (CET)',
                'Time (CET)',
                'Frequency',
                'Capacity Sold',
                'Target Capacity',
                '-5%',
                'Simulated Power',
                '+20% ',
                'Delta Load',
                'Status',
            ]);
        }
    };

    return (
        <div>
            <div className={AuditClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={AuditClasses.FilterContainer}>
                        <div></div>
                        <div>
                            <div className={classes.FieldControl}>
                                <label>Select Market</label>
                                <DropdownComponent
                                    name="market"
                                    options={marketOptions}
                                    defaultValue={selectedMarket}
                                    onChange={(e) => {
                                        if (e.value !== selectedMarket?.value) {
                                            setSelectedMarket(e);
                                        }
                                        updateQueryParams('market', JSON.stringify(e));
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={AuditClasses.FilterContainer}>
                        <div className={AuditClasses.LeftContainer}>
                            <div className={AuditClasses.SelecterContainer}>
                                <label className={AuditClasses.DatePickerLabel}>Date</label>
                                <DatePicker date={date} setDate={setDate} showArrow={true} />
                            </div>
                            <div className={AuditClasses.SelecterContainer}>
                                <label className={AuditClasses.DatePickerLabel}>Start Time</label>
                                <CustomTimePicker
                                    startTime={startTime}
                                    setStartTime={(e) => {
                                        setStartTime(e);
                                        updateQueryParams('startTime', e);
                                    }}
                                    type="start"
                                    maxDetail={'second'}
                                />
                            </div>
                            <div className={AuditClasses.SelecterContainer}>
                                <label className={AuditClasses.DatePickerLabel}>End Time</label>
                                <CustomTimePicker
                                    endTime={endTime}
                                    setEndTime={(e) => {
                                        setEndTime(e);
                                        updateQueryParams('endTime', e);
                                    }}
                                    type="end"
                                    maxDetail={'second'}
                                />
                            </div>
                            <div style={{ display: 'flex', gap: '0.5vw' }}>
                                <div onClick={getEdges}>
                                    <button type="submit" className="btn-primary">
                                        Submit
                                    </button>
                                </div>
                                {/* <div>
                  <CustomTooltip content={"Download All"}>
                    <UilImport
                      size="1.5vw"
                      style={{
                        cursor: "pointer",
                        marginTop: "1.2vw",
                        color: "var(--color-primary)",
                      }}
                    />
                  </CustomTooltip>
                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={classes.AllDevices}>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Edges" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={totalCount || 0} />
                            </span>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Table
                            head={['Edge', 'Instance', 'Customer', 'Action']}
                            keys={['edge', 'instance', 'customer', 'action']}
                            data={tableData}
                            page={page}
                            Pagination={true}
                            limit={LIMIT}
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AuditDownloader;
