// Standard Library Imports
import React, { useEffect, useState } from 'react';

// External library imports
import { useLocation, useHistory } from 'react-router-dom';

// Internal Module Imports
import TabsComponent from '../../../components/Tabs/Tabs';
import Details from './Details/Details';
import Ledger from './Ledger/Ledger';
import { useLoader } from '../../../hooks';
import { AdminService } from '../../../services/AdminService';
import { toast } from 'react-toastify';
import Credits from './Credits';

// CSS Imports
import classes from './Economics.module.css';

const Economics = () => {
    const location = useLocation();
    const history = useHistory();
    const queryParams = new URLSearchParams(location.search);
    const [queryParamsData, setQueryParams] = useState({
        tab: queryParams.get('tab') ? Number(queryParams.get('tab')) : '',
        date: queryParams.get('date') ? queryParams.get('date') : '',
        admin: queryParams.get('admin') ? queryParams.get('admin') : '',
        customer: queryParams.get('customer') ? queryParams.get('customer') : '',
        license: queryParams.get('license') ? queryParams.get('license') : '',
        instance: queryParams.get('instance') ? queryParams.get('instance') : '',
        granularity: queryParams.get('granularity') ? queryParams.get('granularity') : '',
        accountType: queryParams.get('accountType') ? queryParams.get('accountType') : '',
        account: queryParams.get('account') ? queryParams.get('account') : '',
    });
    const updateQueryParams = (type, value) => {
        if (type == 'tab') {
            queryParams.delete('date');
            queryParams.delete('customer');
            queryParams.delete('admin');
            queryParams.delete('accountType');
            queryParams.delete('account');
            setQueryParams({});
        }
        queryParams.set(type, value);
        history.push({ search: queryParams.toString() });
    };
    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.tab ? queryParamsData.tab : 0);
    const [startLoader, stopLoader] = useLoader();
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        fetchAdmins();
    }, []);

    const fetchAdmins = () => {
        AdminService.ReadAll(startLoader, handleFetchSucess, handleError, stopLoader);
    };

    const handleFetchSucess = ({ data }) => {
        setAdmins(data.data);
    };

    const handleError = (err) => {
        toast.error(err?.response?.data?.message);
    };

    const tabs = [
        {
            name: 'Ledger',
            component: <Ledger admins={admins} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 1,
        },
        {
            name: 'Overview',
            component: <Details admins={admins} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 2,
        },
        {
            name: 'Transactions',
            component: <Credits queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />,
            tabId: 3,
        },
    ];

    return (
        <div className={classes.Ventilation + ' ventilation'}>
            <TabsComponent
                tabs={tabs}
                selectedIndex={selectedIndex}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('tab', index);
                }}
            />
        </div>
    );
};

export default Economics;
