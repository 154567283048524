// Standard library imports
import React, { useEffect, useState } from 'react';
// Internal module imports
import Table from '../../../../components/Table/Table';
import Typography from '../../../../components/Typography/Typography';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';

// CSS Imports
import classes from '../../../../styles/Bids.module.css';
import instanceClasses from '../index.module.css';

const Info = ({ data, setInfoModal }) => {
    const [processedData, setProcessedData] = useState([]);
    const approvalValidity = [];
    data.approvalValidity &&
        Object.keys(data.approvalValidity).map((key) => {
            approvalValidity.push({
                name: `Approval Validity (${key}) Start Date`,
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <div className={instanceClasses.TableValue}>{`${momentTimeFormater(data.approvalValidity[key].startDate).format('YYYY-MM-DD')}`}</div>
                    </div>
                ),
            });
            approvalValidity.push({
                name: `Approval Validity (${key}) End Date`,
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <div className={instanceClasses.TableValue}>{`${momentTimeFormater(data.approvalValidity[key].endDate).format('YYYY-MM-DD')}`}</div>
                    </div>
                ),
            });
        });

    useEffect(() => {
        const temp = [
            {
                name: 'Name',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <div className={instanceClasses.TableValue}>{data?.name || '--'}</div>
                    </div>
                ),
            },
            {
                name: 'Market',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <div className={instanceClasses.TableValue}>{data?.market.join(', ') || '--'}</div>
                    </div>
                ),
            },
            {
                name: 'Description',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data.description}>
                            <div className={instanceClasses.TableValue}>{data?.description || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },
            {
                name: 'Approved Capacity (MW)',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <div className={instanceClasses.TableValue}>{data?.approvedCapacity || '--'}</div>
                    </div>
                ),
            },
            {
                name: 'Webshop URL',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <a className={instanceClasses.TableValue} target="_blank" href={data?.webshopUrl}>
                            {data?.webshopUrl || '--'}
                        </a>
                    </div>
                ),
            },
            {
                name: 'Floor',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data?.address?.floor}>
                            <div className={instanceClasses.TableValue}>{data?.address?.floor || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },
            {
                name: 'Street Name',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data?.address?.streetName}>
                            <div className={instanceClasses.TableValue}>{data?.address?.streetName || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },
            {
                name: 'City',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data?.address?.city}>
                            <div className={instanceClasses.TableValue}>{data?.address?.city || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },
            {
                name: 'Pincode',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data?.address?.pincode}>
                            <div className={instanceClasses.TableValue}>{data?.address?.pincode || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },
            {
                name: 'Country',
                value: (
                    <div className={instanceClasses.TableValueParent}>
                        <CustomTooltip content={data?.address?.country}>
                            <div className={instanceClasses.TableValue}>{data?.address?.country || '--'}</div>
                        </CustomTooltip>
                    </div>
                ),
            },

            ...approvalValidity,
            ...(data.meta
                ? Object.keys(data.meta).map((key) => ({
                      name: key,
                      value: (
                          <div className={instanceClasses.TableValueParent}>
                              <div className={instanceClasses.TableValue}>{data.meta[key]}</div>
                          </div>
                      ),
                  }))
                : []),
        ];
        setProcessedData(temp);
    }, []);

    return (
        <div className={instanceClasses.Container}>
            <div>
                <Typography content={'Instance Info'} />
            </div>
            <div>
                <Table head={['Label', 'Value']} keys={['name', 'value']} data={processedData} />
            </div>
            <div className={classes.ButtonContainer}>
                <div>
                    <button type="submit" className="btn-primary" onClick={() => setInfoModal({ data: {}, status: false })}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Info;
