// Standard library imports
import React, { useEffect, useState } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { Form, Formik } from 'formik';
import moment from 'moment';

// Internal module imports
import classes from '../../../../styles/AllDevices.module.css';
import Dropdown from '../../../../components/Inputs/Dropdown';
import { Input } from '../../../../components/Inputs/Input';
import { useLoader } from '../../../../hooks';
import { regulationsService } from '../../../../services/regulationsService';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { momentTimeFormater } from '../../../../utils/dateHelper';
import { PQDownloadValidation } from '../../../../validations/Root/PQDownloadValidation';

const PQDownload = ({ startTime, endTime, date, signalId, setPQModal }) => {
    const [startLoader, stopLoader] = useLoader();
    const [marketOptions, setMarketOptions] = useState([]);

    useEffect(() => {
        getMarkets();
    }, []);

    const getMarkets = () => {
        regulationsService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const temp = [{ label: 'None', value: '' }];
        data.data.map((item) => {
            temp.push({
                label: item.name,
                value: item.name,
            });
        });
        setMarketOptions(temp);
    };

    const handleSubmit = (value) => {
        let params = {
            start: startTime,
            end: endTime,
            date: moment(date).tz('Europe/Berlin').format('YYYY-MM-DD'),
            signalId: signalId,
            market: value.market,
            capacity: value.capacity,
            type: 'PQDownload',
        };

        signalsServiceForRoot.downLoadEnergynetAudit(
            params,
            () => startLoader('read-data'),
            handlePQDownload,
            handleError,
            () => stopLoader('read-data')
        );
    };

    const handlePQDownload = ({ data }) => {
        let formatedData = [];
        let fiveAndTwentyPercent = false;
        if (data.data.data[0].hasOwnProperty('fivePercent')) {
            fiveAndTwentyPercent = true;
        }
        data.data.data.map((e) => {
            let temp = {
                date: momentTimeFormater(e.date).format('DD/MM/YYYY'),
                time: momentTimeFormater(e.time).format('HH:mm:ss'),
                frequency: e.frequency,
                ...(fiveAndTwentyPercent && {
                    '-5%': e.fivePercent,
                }),
                load: e.load,
                ...(fiveAndTwentyPercent && {
                    '+20%': e.twentyPercent,
                }),
            };
            formatedData.push(temp);
        });
        let headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Load'];
        if (fiveAndTwentyPercent) {
            headers = ['Date (CET)', 'Time (CET)', 'Frequency', '-5%', 'Load', '+20% '];
        }
        data.data.data.length && DownloadAsExcel(formatedData, `${data.data?.instanceName} PQ Audit`, headers);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    return (
        <div>
            <Formik
                initialValues={{
                    market: '',
                    capacity: '',
                }}
                enableReinitialize
                validationSchema={PQDownloadValidation}
                onSubmit={(val) => {
                    handleSubmit(val);
                }}
            >
                {({ errors, touched, values, isValidating, ...props }) => (
                    <Form>
                        <div>
                            <div className={classes.FieldControl2}>
                                <label for="market">Market</label>
                                <Dropdown name="market" options={marketOptions} defaultValue={marketOptions[0]} />
                            </div>

                            <div className={classes.FieldControl2}>
                                <label for="capacity">Capacity</label>
                                <Input name="capacity" id="capacity" type="number" style={{ marginTop: '0' }} />
                            </div>
                        </div>

                        <div className={classes.ButtonContainer}>
                            <div>
                                <button
                                    className="btn-secondary"
                                    onClick={() => {
                                        setPQModal(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                            <div>
                                <button className="btn-primary" type="submit">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PQDownload;
