// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import { Form, Formik } from 'formik';
import XLSX from 'xlsx';
import { toast } from 'react-toastify';
import ExcelJS from 'exceljs';
import { UilImport, UilExclamationTriangle, UilBoltAlt } from '@iconscout/react-unicons';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
// Css imports
import Classes from '../../../../styles/AllDevices.module.css';
import AuditClasses from './index.module.css';
// Intenal module imports
import MultipleFilesUpload from '../../../../components/Inputs/FileUpload/MultipleFileUpload';
import { useLoader } from '../../../../hooks';
import { DropdownComponent } from '../../../../components/Inputs/Input';
import { regulationsService } from '../../../../services/regulationsService';
import { momentTimeFormater } from '../../../../utils/timeHelper';
import { DownloadAsExcel } from '../../../../utils/downloadAsExcel';
import { truncateNumber } from '../../../../utils/numberHelper';
import DownloadButton from '../../../../components/Buttons/DownloadButton';
import { setCellData } from '../../../../utils/exceljsHelper';
import { DownloadFileFromBuffer } from '../../../../utils/downloadHelper';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import Table from '../../../../components/Table/Table';

const TEST_TYPE = [
    {
        label: 'Static Ramp Test',
        value: 'staticRampTest',
    },
    {
        label: 'Static Linearity Test',
        value: 'staticLinearityTest',
    },
];

const RAMP_TEST_FREQUENCY = {
    inc: {
        t1: 50.1,
        t2: 50.5,
        t3: 50.1,
    },
    dec: {
        t1: 49.9,
        t2: 49.5,
        t3: 49.9,
    },
};

const LINEARLITY_TEST_FREQUENCY = {
    inc: {
        t1: 50.1,
        t2: 50.2,
        t3: 50.3,
        t4: 50.4,
        t5: 50.5,
        t6: 50.4,
        t7: 50.3,
        t8: 50.2,
        t9: 50.1,
    },
    dec: {
        t1: 49.9,
        t2: 49.8,
        t3: 49.7,
        t4: 49.6,
        t5: 49.5,
        t6: 49.6,
        t7: 49.7,
        t8: 49.8,
        t9: 49.9,
    },
};

const fileNamePrefix = 'PQ Analyse';

const PQAnalyser = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [marketOptions, setMarketOption] = useState([]);
    const [selectedMarket, setSelectedMarket] = useState({});
    const [selectedType, setSelectedType] = useState({});
    const [proccessedFile, setProccessedFiles] = useState([]);

    useEffect(() => {
        getMarkets();
    }, []);

    const getMarkets = () => {
        regulationsService.ReadAll(startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = ({ data }) => {
        const temp = data.data.map((item) => ({
            label: item.name,
            value: item.name,
        }));
        setMarketOption(temp);
        setSelectedMarket(queryParamsData.market ? JSON.parse(queryParamsData.market) : temp[0]);
    };

    const handleUploadClick = async (e) => {
        try {
            startLoader();
            if (selectedMarket?.value === 'FCR-D-INC' || selectedMarket?.value === 'FCR-D-DEC') {
                const files = Array.from(e.target.files);
                const temp = [];
                const promises = files.map((el) => {
                    const file = el;
                    const energinetFileName = file.name.split('.').join(' Energinet.xlsx.');
                    return new Promise((resolve, reject) => {
                        const reader = new FileReader();
                        reader.onload = async (event) => {
                            try {
                                const arrayBuffer = event.target.result;
                                const binaryString = new Uint8Array(arrayBuffer).reduce((data, byte) => {
                                    return data + String.fromCharCode(byte);
                                }, '');
                                const workbook = XLSX.read(binaryString, { type: 'binary' });
                                const sheetName = workbook.SheetNames[0];
                                const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                                const column = Object.keys(excelData[0]);
                                if (column.includes('VALUE_FREQUENCY') && column.includes('VALUE_POWER') && column.includes('TIMESTAMP')) {
                                    let result = {};
                                    if (selectedType?.value === 'staticRampTest') {
                                        result = await rampTest(excelData, energinetFileName);
                                    } else {
                                        result = await linearityTest(excelData, energinetFileName);
                                    }
                                    temp.push({
                                        fileName: file.name,
                                        energinetFileName: energinetFileName,
                                        standard: result?.standard || [],
                                        energinet: result?.energinetDataWorkbook || [],
                                        isVerified: result.status ? true : false,
                                        action: result.status ? (
                                            <div className={AuditClasses.ActionButton}>
                                                <div>
                                                    <CustomTooltip content={'Standard Format'}>
                                                        <div onClick={() => handleDownloadLinearity(result.standard, 'standard', file.name)}>
                                                            <UilImport
                                                                size="1.2vw"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'var(--color-primary)',
                                                                }}
                                                            />
                                                        </div>
                                                    </CustomTooltip>
                                                </div>
                                                <div>
                                                    <CustomTooltip content={'Energinet Format'}>
                                                        <div onClick={() => handleDownloadLinearity(result.energinetDataWorkbook, 'energinet', energinetFileName)}>
                                                            <UilBoltAlt
                                                                size="1.2vw"
                                                                style={{
                                                                    cursor: 'pointer',
                                                                    color: 'var(--color-primary)',
                                                                }}
                                                            />
                                                        </div>
                                                    </CustomTooltip>
                                                </div>
                                            </div>
                                        ) : (
                                            <div>
                                                <CustomTooltip content={result.error}>
                                                    <div>
                                                        <UilExclamationTriangle
                                                            size="1.2vw"
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'var(--color-primary)',
                                                            }}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            </div>
                                        ),
                                    });
                                } else {
                                    const error = [];
                                    if (!column.includes('TIMESTAMP')) {
                                        error.push('TIMESTAMP');
                                    }
                                    if (!column.includes('VALUE_FREQUENCY')) {
                                        error.push('VALUE_FREQUENCY');
                                    }
                                    if (!column.includes('VALUE_POWER')) {
                                        error.push('VALUE_POWER');
                                    }

                                    temp.push({
                                        fileName: file.name,
                                        energinetFileName: energinetFileName,
                                        standard: [],
                                        energinet: [],
                                        isVerified: false,
                                        action: (
                                            <div>
                                                <CustomTooltip content={`${error.join(', ')} Column missing`}>
                                                    <div>
                                                        <UilExclamationTriangle
                                                            size="1.2vw"
                                                            style={{
                                                                cursor: 'pointer',
                                                                color: 'var(--color-primary)',
                                                            }}
                                                        />
                                                    </div>
                                                </CustomTooltip>
                                            </div>
                                        ),
                                    });
                                }
                                resolve();
                            } catch (error) {
                                reject(error);
                            }
                        };
                        reader.readAsArrayBuffer(file);
                    });
                });
                await Promise.all(promises);
                setProccessedFiles(temp);
            } else {
                toast.warn('Tool is in progress.');
                setProccessedFiles([]);
            }
            stopLoader();
        } catch (err) {
            stopLoader();
            toast.error(`Error while processing file`);
        }
    };

    const checkDuplicateTimestamp = (data) => {
        const temp = [];
        const duplicateTimestamp = [];
        let count = 0;
        data.map((i) => {
            if (temp.includes(i.TIMESTAMP)) {
                count += 1;
                if (!duplicateTimestamp.includes(i.TIMESTAMP)) {
                    duplicateTimestamp.push(i.TIMESTAMP);
                }
            } else {
                temp.push(i.TIMESTAMP);
            }
        });
        return { count, duplicateTimestamp };
    };

    const rampTest = async (data = [], fileName) => {
        let freq = {};
        if (selectedMarket.value === 'FCR-D-INC') {
            freq = RAMP_TEST_FREQUENCY.inc;
        } else if (selectedMarket.value === 'FCR-D-DEC') {
            freq = RAMP_TEST_FREQUENCY.dec;
        }
        if (data.length === 2280) {
            const duplicate = checkDuplicateTimestamp(data);
            if (duplicate.count === 0) {
                const firstTest = data.slice(0, 180).every((obj) => obj.VALUE_FREQUENCY == freq.t1);
                const secondTest = data.slice(180, 1080).every((obj) => obj.VALUE_FREQUENCY == freq.t2);
                const thirdTest = data.slice(1080, 2280).every((obj) => obj.VALUE_FREQUENCY == freq.t3);
                if (!firstTest || !secondTest || !thirdTest) {
                    if (!firstTest) {
                        return {
                            status: false,
                            error: `0-180 range ${freq.t1} frequency not correct`,
                        };
                    } else if (!secondTest) {
                        return {
                            status: false,
                            error: `180-1080 range ${freq.t2} frequency not correct`,
                        };
                    } else if (!thirdTest) {
                        return {
                            status: false,
                            error: `1080-2280 range ${freq.t3} frequency not correct`,
                        };
                    }
                } else {
                    const energinetDataWorkbook = await constructEnerginetFormat(data, fileName);
                    return {
                        standard: data,
                        energinetDataWorkbook: energinetDataWorkbook,
                        status: true,
                    };
                }
            } else {
                return {
                    status: false,
                    error: `${duplicate.count} duplicate timestamps (${duplicate.duplicateTimestamp.join(', ')})`,
                };
            }
        } else {
            if (data.length > 2280) {
                const diff = data.length - 2280;
                return {
                    status: false,
                    error: `${diff} extra timestamps`,
                };
            } else {
                const diff = 2280 - data.length;
                return {
                    status: false,
                    error: `${diff} timestamps missing`,
                };
            }
        }
    };

    const constructEnerginetFormat = async (data, fileName) => {
        const colors = {
            peachOrange: 'FFFFCC99',
            pureOrange: 'FFFF7D00',
            lightYellow: 'FFFFFFCC',
            lightGray: 'FFF2F2F2',
        };
        const boldFont = { bold: true };
        const borderStyle = {
            border: {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' },
            },
        };
        const fontSize = (size = 11) => ({ size });
        const backgroundColor = (color) => ({
            fill: {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: colors[color] },
            },
        });
        const fontColor = (color) => ({ color: { argb: colors[color] } });
        const alignMiddle = {
            alignment: { horizontal: 'center', vertical: 'middle' },
        };
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(fileName);

        // Increase the height of row
        const row1 = sheet.getRow(1);
        row1.height = 30;

        // Increase the width of column
        for (let i = 2; i <= 6; i++) {
            const character = String.fromCharCode(65 + i);
            const column = sheet.getColumn(character);
            column.width = 20;
        }
        const column = sheet.getColumn('H');
        column.width = 30;
        const AColumn = sheet.getColumn('A');
        AColumn.width = 10;

        // Merge cells B1 and C1 and set value to 'Dataark'
        sheet.mergeCells('B1:C1');
        setCellData(sheet, 'B1', 'Dataark', {
            font: { ...boldFont, ...fontSize(20) },
        });

        sheet.mergeCells('B2:C2');
        setCellData(sheet, 'B2', 'Resultater findes i næste ark.');
        sheet.mergeCells('F2:H2');
        setCellData(sheet, 'F2', 'ENDK beder om data fra tidspunktet der er leveret den sande ', { font: { ...boldFont, ...fontSize(12) } });
        sheet.mergeCells('F3:H3');
        setCellData(sheet, 'F3', 'frekvens for.Det fremgår i kolonne A, B og C længere nede.', { font: { ...boldFont, ...fontSize(12) } });
        sheet.mergeCells('F4:H4');
        setCellData(sheet, 'F4', 'Opløsningen af data skal være på 1 s, fra 10 s før udfaldet til 180 s efter.', { font: { ...boldFont, ...fontSize(12) } });
        sheet.mergeCells('F5:H5');
        setCellData(sheet, 'F5', 'Felter markeret med denne farve bedes udfyldes.', {
            ...backgroundColor('peachOrange'),
            font: { ...boldFont },
            ...alignMiddle,
        });

        sheet.mergeCells('F8:G8');
        setCellData(sheet, 'F8', 'Anlægsnavn, type:', {
            font: { ...boldFont },
            ...alignMiddle,
        });
        sheet.mergeCells('F9:G9');
        setCellData(sheet, 'F9', 'Anlægs maksimal effekt [MW]:', {
            font: { ...boldFont },
            ...alignMiddle,
        });
        sheet.mergeCells('F10:G10');
        setCellData(sheet, 'F10', 'Anlægs minimumseffekt [MW]:', {
            font: { ...boldFont },
            ...alignMiddle,
        });

        setCellData(sheet, 'H8', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });
        setCellData(sheet, 'H9', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });
        setCellData(sheet, 'H10', '', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
        });

        setCellData(sheet, 'A14', 'Fx');
        setCellData(sheet, 'B14', 'Tiden', {
            ...borderStyle,
            ...backgroundColor('lightGray'),
            font: { ...fontColor('pureOrange'), ...boldFont },
        });
        sheet.mergeCells('F14:I14');
        setCellData(sheet, 'F14', 'Vær opmærksom på timeskift. Derfor er "Planlagt Effekt" en kolonne, og ikke en enkelt celle. ');

        setCellData(sheet, 'A15', '', {
            ...borderStyle,
            ...backgroundColor('lightYellow'),
        });
        setCellData(sheet, 'B15', '', {
            ...borderStyle,
            ...backgroundColor('lightYellow'),
        });
        sheet.mergeCells('C15:D15');
        setCellData(sheet, 'C15', 'Indsættes af ENDK', {
            ...borderStyle,
            ...backgroundColor('lightYellow'),
            ...alignMiddle,
        });
        sheet.mergeCells('E15:G15');
        setCellData(sheet, 'E15', 'Indsættes af leverandør', {
            ...borderStyle,
            ...backgroundColor('peachOrange'),
            ...alignMiddle,
        });
        setCellData(sheet, 'H15', '', { ...backgroundColor('peachOrange') });

        setCellData(sheet, 'A16', 'Dato', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'B16', 'Tid', {
            ...borderStyle,
            font: { ...boldFont },
            ...alignMiddle,
        });
        setCellData(sheet, 'C16', 'ENDK frekvens (Sand) [Hz]', {
            ...borderStyle,
            font: { ...boldFont },
        });
        setCellData(sheet, 'D16', 'ENDK Frekvensafvigelse [mHz]', {
            ...borderStyle,
            font: { ...boldFont },
        });
        setCellData(sheet, 'E16', 'Frekvens afvigelse [mHz]', {
            ...borderStyle,
            font: { ...boldFont },
        });
        setCellData(sheet, 'F16', 'Blok effekt [MW]', {
            ...borderStyle,
            font: { ...boldFont },
        });
        setCellData(sheet, 'G16', 'Planlagt effekt [MW]', {
            ...borderStyle,
            font: { ...boldFont },
        });
        setCellData(sheet, 'H16', 'Anlægs primære reserve effekt [MW]', {
            ...borderStyle,
            font: { ...boldFont },
        });

        const startRow = 17;
        data.forEach((row, idx) => {
            const date = momentTimeFormater(row.TIMESTAMP).format('YYYY-MM-DD');
            const time = momentTimeFormater(row.TIMESTAMP).format('HH:mm:ss');

            const calculatedFrequency = (row.VALUE_FREQUENCY - 50) * 1000;

            setCellData(sheet, `A${startRow + idx}`, date, {
                ...borderStyle,
                ...backgroundColor('lightYellow'),
            });
            setCellData(sheet, `B${startRow + idx}`, time, {
                ...borderStyle,
                ...backgroundColor('lightYellow'),
            });
            setCellData(sheet, `C${startRow + idx}`, row.VALUE_FREQUENCY);
            setCellData(sheet, `D${startRow + idx}`, calculatedFrequency);
            setCellData(sheet, `E${startRow + idx}`, calculatedFrequency, {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
            setCellData(sheet, `F${startRow + idx}`, row.VALUE_POWER / 1000, {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
            setCellData(sheet, `G${startRow + idx}`, '', {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
            setCellData(sheet, `H${startRow + idx}`, '', {
                ...borderStyle,
                ...backgroundColor('peachOrange'),
            });
        });

        const buffer = await workbook.xlsx.writeBuffer();
        return buffer;
    };

    const linearityTest = async (data = [], fileName) => {
        let freq = {};
        if (selectedMarket.value === 'FCR-D-INC') {
            freq = LINEARLITY_TEST_FREQUENCY.inc;
        } else if (selectedMarket.value === 'FCR-D-DEC') {
            freq = LINEARLITY_TEST_FREQUENCY.dec;
        }
        if (data.length === 1080) {
            const duplicate = checkDuplicateTimestamp(data);
            if (duplicate.count === 0) {
                const test1 = data.slice(0, 120).every((obj) => obj.VALUE_FREQUENCY == freq.t1);
                const test2 = data.slice(120, 240).every((obj) => obj.VALUE_FREQUENCY == freq.t2);
                const test3 = data.slice(240, 360).every((obj) => obj.VALUE_FREQUENCY == freq.t3);
                const test4 = data.slice(360, 480).every((obj) => obj.VALUE_FREQUENCY == freq.t4);
                const test5 = data.slice(480, 600).every((obj) => obj.VALUE_FREQUENCY == freq.t5);
                const test6 = data.slice(600, 720).every((obj) => obj.VALUE_FREQUENCY == freq.t6);
                const test7 = data.slice(720, 840).every((obj) => obj.VALUE_FREQUENCY == freq.t7);
                const test8 = data.slice(840, 960).every((obj) => obj.VALUE_FREQUENCY == freq.t8);
                const test9 = data.slice(960, 1080).every((obj) => obj.VALUE_FREQUENCY == freq.t9);

                if (!test1 || !test2 || !test3 || !test4 || !test5 || !test6 || !test7 || !test8 || !test9) {
                    if (!test1) {
                        return {
                            status: false,
                            error: `0-120 range ${freq.t1} frequency not correct`,
                        };
                    } else if (!test2) {
                        return {
                            status: false,
                            error: `120-240 range ${freq.t2} frequency not correct`,
                        };
                    } else if (!test3) {
                        return {
                            status: false,
                            error: `240-360 range ${freq.t3} frequency not correct`,
                        };
                    } else if (!test4) {
                        return {
                            status: false,
                            error: `360-480 range ${freq.t4} frequency not correct`,
                        };
                    } else if (!test5) {
                        return {
                            status: false,
                            error: `480-600 range ${freq.t5} frequency not correct`,
                        };
                    } else if (!test6) {
                        return {
                            status: false,
                            error: `600-720 range ${freq.t6} frequency not correct`,
                        };
                    } else if (!test7) {
                        return {
                            status: false,
                            error: `720-840 range ${freq.t7} frequency not correct`,
                        };
                    } else if (!test8) {
                        return {
                            status: false,
                            error: `840-960 range ${freq.t8} frequency not correct`,
                        };
                    } else if (!test9) {
                        return {
                            status: false,
                            error: `960-1080 range ${freq.t9} frequency not correct`,
                        };
                    }
                } else {
                    const energinetDataWorkbook = await constructEnerginetFormat(data, fileName);
                    return {
                        standard: data,
                        energinetDataWorkbook: energinetDataWorkbook,
                        status: true,
                    };
                }
            } else {
                return {
                    status: false,
                    error: `${duplicate.count} duplicate timestamps (${duplicate.duplicateTimestamp.join(', ')})`,
                };
            }
        } else {
            if (data.length > 1080) {
                const diff = data.length - 1080;
                return {
                    status: false,
                    error: `${diff} extra timestamps`,
                };
            } else {
                const diff = 1080 - data.length;
                return {
                    status: false,
                    error: `${diff} timestamps missing`,
                };
            }
        }
    };

    const handleDownloadLinearity = (data, type = 'standard', fileName) => {
        if (type === 'energinet') {
            DownloadFileFromBuffer(data, `${fileNamePrefix} ${fileName}`, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        } else {
            downloadFile(data, fileName);
        }
    };

    const downloadZipFile = async (type) => {
        startLoader();
        const zip = new JSZip();
        proccessedFile.map((item) => {
            if (item.isVerified) {
                if (type == 'standard') {
                    const headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Power'];
                    const worksheetData = [
                        headers,
                        ...item.standard.map((i) => [
                            momentTimeFormater(i.TIMESTAMP).format('YYYY-MM-DD'),
                            momentTimeFormater(i.TIMESTAMP).format('HH:mm:ss'),
                            truncateNumber(i.VALUE_FREQUENCY, 3),
                            truncateNumber(i.VALUE_POWER, 3),
                        ]),
                    ];
                    const ws = XLSX.utils.aoa_to_sheet(worksheetData);
                    const wb = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                    const buffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                    const excelBlob = new Blob([new Uint8Array(buffer)], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    zip.file(item.fileName, excelBlob);
                } else {
                    const excelBlob = new Blob([item.energinet], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    zip.file(item.energinetFileName, excelBlob);
                }
            }
        });
        const zipBlob = await zip.generateAsync({ type: 'blob' });
        saveAs(zipBlob, `${fileNamePrefix} ${type} format.zip`);
        stopLoader();
    };

    const downloadFile = (data, fileName) => {
        const formatedData = data.map((i) => {
            return {
                date: momentTimeFormater(i.TIMESTAMP).format('YYYY-MM-DD'),
                time: momentTimeFormater(i.TIMESTAMP).format('HH:mm:ss'),
                frequency: truncateNumber(i.VALUE_FREQUENCY, 3),
                power: truncateNumber(i.VALUE_POWER, 3),
            };
        });
        const headers = ['Date (CET)', 'Time (CET)', 'Frequency', 'Power'];
        DownloadAsExcel(formatedData, `${fileNamePrefix} ${fileName}`, headers);
    };

    const handleError = (err) => {
        console.log(err);
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div>
            <div className={AuditClasses.Filter}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className={AuditClasses.FilterContainer}>
                        <div></div>
                        <div>
                            <div className={Classes.FieldControl}>
                                <label>Select Market</label>
                                <DropdownComponent
                                    name="market"
                                    options={marketOptions}
                                    defaultValue={selectedMarket}
                                    onChange={(e) => {
                                        if (e.value !== selectedMarket?.value) {
                                            setSelectedMarket(e);
                                            setProccessedFiles([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <div>
                            <div className={Classes.FieldControl}>
                                <label>Select Type</label>
                                <DropdownComponent
                                    name="type"
                                    options={TEST_TYPE}
                                    defaultValue={TEST_TYPE[0]}
                                    onChange={(e) => {
                                        if (e.value !== selectedType?.value) {
                                            setSelectedType(e);
                                            setProccessedFiles([]);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={AuditClasses.FilterContainer}></div>
                </div>
            </div>
            <div className={Classes.AllDevices}>
                <div className={AuditClasses.UploadFileContainer}>
                    <Formik initialValues={{}} enableReinitialize>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={AuditClasses.FileContainer}>
                                        <div className={Classes.InputContainer}>
                                            <div className={AuditClasses.DatePickerLabel} style={{ width: '35vw' }}>
                                                <label>Upload file</label>
                                                <MultipleFilesUpload
                                                    name="file"
                                                    id="file"
                                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                    onChange={handleUploadClick}
                                                    multiple
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                    {proccessedFile.length ? (
                        <div>
                            <div className={`${AuditClasses.FileContainer} ${AuditClasses.DownloadButtonControl}`}>
                                <DownloadButton size={'medium'} text={'All files - Standard Format'} onClick={() => downloadZipFile('standard')} />
                                <DownloadButton size={'medium'} text={'All files - Energinet Format'} onClick={() => downloadZipFile('energinet')} />
                            </div>
                            <div style={{ minWidth: '35vw' }}>
                                <Table head={['File', 'Action']} keys={['fileName', 'action']} data={proccessedFile} />
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </div>
    );
};

export default PQAnalyser;
