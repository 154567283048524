// Standard library imports
import { useEffect, useState } from 'react';
// External library imports
import { toast } from 'react-toastify';
import { UilTrash } from '@iconscout/react-unicons';
// Internal module imports
import classes from '../../../styles/Bids.module.css';
import Table from '../../../components/Table/Table';
import Typography from '../../../components/Typography/Typography';
import { useLoader } from '../../../hooks/use-loader.hook';
import { momentTimeFormater } from '../../../utils/timeHelper';
import DeleteModalComponent from '../../../components/DeleteModal/DeleteModal';
import { DropdownComponent } from '../../../components/Inputs/Input';
import { AdminBiddingService } from '../../../services/AdminBiddingService';

const LIMIT = 15;

const FILTER_OPTIONS = [
    { label: 'Current', value: 'current' },
    { label: 'Past', value: 'past' },
];

const MaintenanceHours = ({ market, customers = [{}], updateQueryParams, queryParamsData }) => {
    const [startLoader, stopLoader] = useLoader();
    const [data, setData] = useState([]);
    const [refresh, setRefresh] = useState(null);
    const [deleteModal, setDeleteModal] = useState({ status: false, id: '' });
    const [customer, setCustomer] = useState(queryParamsData.customer ? queryParamsData.customer : {});
    const [filterType, setFilterType] = useState(queryParamsData.filter ? queryParamsData.filter : FILTER_OPTIONS[0]);
    const [skip, setSkip] = useState(0);
    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    useEffect(() => {
        const queryMatched = matchQueryParams(customers, queryParamsData.customer);
        !queryMatched && setCustomer(customers[0]);
    }, [customers]);

    useEffect(() => {
        fetchRequests();
    }, [refresh, skip, filterType, customer]);

    const matchQueryParams = (data = [], match = {}) => {
        for (let item of data) {
            if (item.value === match?.value) {
                return true;
            }
        }
        return false;
    };

    const fetchRequests = () => {
        if (customer?.value) {
            const params = {
                limit: LIMIT,
                skip: skip,
                customerId: customer?.value,
                filterType: filterType?.value,
                market: market,
            };
            AdminBiddingService.GetMaintenanceHours(params, startLoader, handleFetchSuccess, handleError, stopLoader);
        }
    };

    const handleFetchSuccess = ({ data }) => {
        if (Array.isArray(data.data?.data)) {
            const processed = data.data.data.map((i) => ({
                ...i,
                date: momentTimeFormater(i.date).format('YYYY-MM-DD'),
                instance: i.groupId?.name,
                customer: i.customerName,
                hoursT: i.hourSlots?.toString(),
                action: <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} onClick={() => setDeleteModal({ status: true, id: i._id })} />,
            }));
            setData(processed);
        } else {
            setData([]);
        }
        setTotalCount(data.data?.count ? data.data.count : 0);
    };

    const handleDelete = (id) => {
        AdminBiddingService.DeleteMaintenanceHours(id, startLoader, deleteSuccess, handleError, stopLoader);
    };

    const deleteSuccess = () => {
        toast.success('Maintenance record deleted successfully!');
        setRefresh(Math.random() * 1000);
    };

    const handleError = (error) => {
        console.log(error);
    };

    const handlePageChange = (pageno) => {
        setSkip(LIMIT * pageno);
        setPage(pageno);
    };

    return (
        <div className={classes.Bids}>
            <div className={classes.Header} style={{ marginTop: '1vw' }}>
                <div>
                    <Typography content="Request Bid" />
                    <div className={classes.TableCount}>
                        Total Count :
                        <span>
                            <Typography size="14" content={data.length} />
                        </span>
                    </div>
                    <div style={{ width: '9vw' }}>
                        <DropdownComponent
                            name="customerId"
                            options={customers}
                            defaultValue={queryParamsData.customer ? queryParamsData.customer : customer}
                            onChange={(e) => {
                                setCustomer(e);
                                updateQueryParams('customer', JSON.stringify(e));
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '9vw' }}>
                    <DropdownComponent
                        name="type"
                        options={FILTER_OPTIONS}
                        defaultValue={filterType}
                        onChange={(e) => {
                            setFilterType(e);
                            updateQueryParams('filter', JSON.stringify(e));
                        }}
                    />
                </div>
            </div>
            <Table
                head={['Date', 'Customer', 'Instance', 'Market', 'Hour Slots', 'Action']}
                keys={['date', 'customer', 'instance', 'biddingMarket', 'hoursT', 'action']}
                data={data}
                page={page}
                Pagination={true}
                limit={LIMIT}
                handlePageChange={handlePageChange}
                totalCount={totalCount}
            />
            <DeleteModalComponent
                deletefunction={handleDelete}
                opendeleteModal={deleteModal.status}
                setOpenDeleteModal={(status) => setDeleteModal({ id: '', status })}
                deviceId={deleteModal.id}
            ></DeleteModalComponent>
        </div>
    );
};

export default MaintenanceHours;
