import React, { useEffect, useState } from 'react';
import { AdminBiddingService } from '../../../services/AdminBiddingService';
import { TextArea } from '../../../components/Inputs/Input';
import DatePicker from '../../../components/Inputs/DatePicker/DatePicker';
import { Formik, Form } from 'formik';
import { toast } from 'react-toastify';
import { useLoader } from '../../../hooks';
import Typography from '../../../components/Typography/Typography';
import bidsClasses from '../index.module.css';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import classes from '../../../styles/BidModal.module.css';
import { notesValidation } from '../../../validations/Bidding/Bids';
import { momentTimeFormater } from '../../../utils/timeHelper';
import CustomTooltip from '../../../components/CustomToolTip/CustomTooltip';
import { UilTrash } from '@iconscout/react-unicons';
import DeleteModal from '../../../components/DeleteModal/DeleteModal';

export const Notes = ({ data, setOpen }) => {
    const [startLoader, stopLoader] = useLoader();
    const [notes, setNotes] = useState([]);
    const [date, setdate] = useState(new Date());
    const [createModal, setCreateModal] = useState({ status: false });
    const [deleteModal, setDeleteModal] = useState({
        status: false,
        id: null,
        note_id: null,
    });

    useEffect(() => {
        if (data?._id) {
            getNotes();
        }
    }, [data]);

    const handleSubmit = (values) => {
        const payload = {
            date,
            ...values,
        };
        AdminBiddingService.CreateNote(data?._id, payload, startLoader, handleSuccess, handleError, stopLoader);
    };

    const handleSuccess = () => {
        setCreateModal({ status: false });
        getNotes();
        toast.success('Note created.');
    };

    const handleError = (err) => {
        if (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    const getNotes = () => {
        AdminBiddingService.GetNote(data._id, startLoader, handleGetSuccess, handleError, stopLoader);
    };

    const handleGetSuccess = ({ data }) => {
        const processed = data.data.notes.map((item) => ({
            description: item.description,
            date: momentTimeFormater(item.date).format('YYYY-MM-DD'),
            user: item?.userId?.name || '--',
            note_id: item._id,
        }));
        setNotes(processed);
    };

    const handleDelete = () => {
        AdminBiddingService.DeleteNote(deleteModal.id, { noteId: deleteModal.note_id }, startLoader, handleDeleteSuccess, handleError, stopLoader);
    };

    const handleDeleteSuccess = () => {
        toast.success('Note deleted!');
        getNotes();
    };

    return (
        <div>
            <div style={{ maxHeight: '70vh', width: '50vw', overflow: 'auto' }}>
                <div className={bidsClasses.wholeFilterWrapper}>
                    <div>
                        <Typography content={'Notes'} />
                    </div>
                    <div>
                        <div onClick={() => setCreateModal({ status: true })}>
                            <button className="btn-primary" style={{ marginTop: 0 }}>
                                Create Note
                            </button>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={bidsClasses.CardsContainer}>
                        {notes.map((note, key) => (
                            <div className={bidsClasses.Card} key={key}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div className={bidsClasses.SubTitleWrapper}>
                                        <div className={bidsClasses.SubTitle}>Created By: {note.user}</div>
                                        <div className={bidsClasses.SubTitle}>Created At: {note.date}</div>
                                    </div>
                                    <div>
                                        <div>
                                            <CustomTooltip content={'Delete'}>
                                                <UilTrash
                                                    size={'1.2vw'}
                                                    style={{ color: 'var(--color-primary)' }}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setDeleteModal({
                                                            status: true,
                                                            id: data._id,
                                                            note_id: note.note_id,
                                                        });
                                                    }}
                                                />
                                            </CustomTooltip>
                                        </div>
                                    </div>
                                </div>

                                <div className={bidsClasses.Content}>
                                    <span>{note.description}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={classes.ButtonContainer}>
                    <div>
                        <button type="button" className="btn-secondary" onClick={() => setOpen({ status: false })}>
                            Close
                        </button>
                    </div>
                </div>
            </div>

            <ModalComponent isOpen={createModal.status} setOpen={() => setCreateModal({ status: false })}>
                <div style={{ width: '30vw' }}>
                    <div>
                        <Typography content={'Create'} />
                    </div>
                    <div>
                        <Formik enableReinitialize initialValues={{ description: '' }} onSubmit={handleSubmit} validationSchema={notesValidation}>
                            {({ errors, touched, values, isValidating, ...props }) => (
                                <Form>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label>
                                                Date <span className="required">*</span>
                                            </label>
                                            <div className="modal-date-picker">
                                                <DatePicker date={date} setDate={setdate} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl2}>
                                            <label>
                                                Desciption <span className="required">*</span>
                                            </label>
                                            <TextArea
                                                name={`description`}
                                                setFieldValue={props.setFieldValue}
                                                setFieldTouched={props.setFieldTouched}
                                                getFieldMeta={props.getFieldMeta}
                                            />
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setCreateModal({ status: false })}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </ModalComponent>
            <DeleteModal deletefunction={handleDelete} opendeleteModal={deleteModal.status} setOpenDeleteModal={(status) => setDeleteModal({ id: null, status, note_id: null })} />
        </div>
    );
};
