// Standard library imports
import React, { useEffect, useState } from 'react';

// Extenal library imports
import { Form, Formik, FieldArray } from 'formik';
import { toast } from 'react-toastify';
import { UilMinusCircle, UilPlusCircle } from '@iconscout/react-unicons';
// Internal module imports
import Typography from '../../../components/Typography/Typography';
import { EdgeService } from '../../../services/EdgeService';
import { useLoader } from '../../../hooks';
import Dropdown from '../../../components/Inputs/Dropdown';
import { Input, ToggleButton } from '../../../components/Inputs/Input';
import { EdgeScheduleTest } from '../../../validations/Edge/ScheduleTestValidator';
import SaveProfileTest from './SaveProfileTest';

// Css imports
import classes from '../../../styles/CreateDevice.module.css';
import edgeClasses from '../Edge.module.css';
import bidclasses from '../../../styles/Bids.module.css';
import allDeviceclasses from '../../../styles/AllDevices.module.css';
import ModalComponent from '../../../components/ModalComponent/ModalComponent';
import moment from 'moment';
import { HttpClient } from '../../../utils/httpClient';
import Table from '../../../components/Table/Table';
import { momentTimeFormater } from '../../../utils/timeHelper';
import DownloadButton from '../../../components/Buttons/DownloadButton';
import { DownloadAsExcel } from '../../../utils/downloadAsExcel';
import { uuidGenerator } from '../../../utils/uuidGenarator';

const Schedule = ({ edgeData, setScheduleModal, setRedirectTo, setTestLogs, setSelectedIndex, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();
    const [testOption, setTestOption] = useState([]);
    const [selectedTestOption, setSelectedTestOption] = useState({});
    const [saveTestModal, setSaveTestModal] = useState(false);
    const [fieldType, setFieldType] = useState({
        label: 'Frequency',
        value: 'frequency',
    });
    const [curInterval, setCurInterval] = useState(null);
    const [_currentIndex, setCurrentIndex] = useState(0);
    const [changeScreen, setChangeScreen] = useState(false);
    const [afrrTestLogs, setAfrrTestLogs] = useState([]);
    const timestampSent = [];

    useEffect(() => {
        if (edgeData.market == 'aFRR') {
            setFieldType({ label: 'Set Point', value: 'setPoint' });
        } else {
            setFieldType({ label: 'Frequency', value: 'frequency' });
        }
    }, [edgeData.market]);

    useEffect(() => {
        const params = {
            market: edgeData.market == 'aFRR' ? edgeData.market : 'general',
        };
        EdgeService.GetPredefinedTest(params, startLoader, handleGetPredefinedTestSuccess, handleError, stopLoader);
    }, []);

    const handleGetPredefinedTestSuccess = ({ data }) => {
        const temp = [];
        data?.data.map((item) => {
            temp.push({
                label: item.name,
                value: item.profile,
                isPower: item?.isPower || false,
            });
        });
        setTestOption(temp);
    };

    const handleSubmit = (values) => {
        let profiles = values.profileTest;
        if (!values.isPower) {
            profiles = values.profileTest.map((p) => ({
                [fieldType.value]: p[fieldType.value],
                seconds: p.seconds,
            }));
        }
        const payload = {
            profiles: profiles,
            edgeId: edgeData.deviceId,
            type: selectedTestOption?.label || '',
        };
        EdgeService.SendScheduleData(payload, startLoader, handleSendScheduleSuccess, handleError, stopLoader);
    };

    const handleSubmitForAfrr = (values) => {
        setChangeScreen(true);
        const { profileTest = [] } = values;
        let splitSecProfiles = [];

        profileTest.forEach((p) => {
            const splitSec = Array(p.seconds / 10)
                .fill(null)
                .map(() => ({
                    setPoint: p.setPoint,
                    status: p.setPoint !== 0 ? 'ON' : 'OFF',
                    activationDirection: p.setPoint > 0 ? 'INC' : Number(p.setPoint) === 0 ? 'NEUTRAL' : 'DEC',
                    mqttStatus: 'Pending',
                    mode: 'TEST',
                    commandId: uuidGenerator(),
                }));
            splitSecProfiles.push(splitSec);
        });

        splitSecProfiles = splitSecProfiles.flat();
        splitSecProfiles.push({
            setPoint: 0,
            status: 'OFF',
            activationDirection: 'NEUTRAL',
            mqttStatus: 'Pending',
            mode: 'TEST',
            commandId: uuidGenerator(),
        });

        setAfrrTestLogs(splitSecProfiles);

        if (curInterval) {
            clearInterval(curInterval);
        }

        const interval = setInterval(() => {
            setCurrentIndex((i) => {
                const val = splitSecProfiles[i];
                val.timestamp = moment().utc().format();

                sendValuesForAfrr(val, i);

                const nextIndex = i + 1;
                if (splitSecProfiles[nextIndex]) {
                    return nextIndex;
                } else {
                    clearInterval(interval);
                    setCurInterval(null);
                    return 0;
                }
            });
        }, 10000);

        setCurInterval(interval);
    };

    const sendValuesForAfrr = (value, index) => {
        const timestamp = moment().utc().format();

        setAfrrTestLogs((p) => {
            const old = p[index];
            old.timestamp = timestamp;
            old.timestampCET = momentTimeFormater(timestamp).format('YYYY-MM-DD HH:mm:ss');
            old.mqttStatus = 'Sending';
            return p;
        });

        if (!timestampSent.includes(timestamp)) {
            const payload = {
                profile: value,
                edgeId: edgeData.deviceId,
                type: 'aFRR',
                index,
                timestamp,
            };
            timestampSent.push(timestamp);
            EdgeService.SendScheduleAfrrData(
                payload,
                () => { },
                (res) => handleAfrrSuccess(res, index),
                (res) => handleAfrrError(res, index),
                () => { }
            );
        }
    };

    const handleAfrrSuccess = ({ data }, index) => {
        setAfrrTestLogs((p) => {
            const old = p[index];
            const message = data.data?.status;
            if (message) {
                old.response = message;
            }
            old.mqttStatus = 'Sent';
            const oldArray = [...p];
            oldArray[index] = old;
            return oldArray;
        });
    };

    const handleAfrrError = (err, index) => {
        if (err.response?.data?.message) {
            setAfrrTestLogs((p) => {
                const old = p[index];
                old.response = err.response.data.message;
                old.mqttStatus = 'Failed';
                const oldArray = [...p];
                oldArray[index] = old;
                return oldArray;
            });
        }
    };

    const handleSendScheduleSuccess = ({ data }) => {
        toast.success(data?.data?.status ? data?.data?.status : 'No Response From Edge.');
        setScheduleModal({ status: false, data: '' });
        if (edgeData.market !== 'aFRR') {
            setRedirectTo((prev) => ({ ...prev, status: true }));
        }
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleDownloadAfrr = () => {
        if (!curInterval) {
            const data = afrrTestLogs.map((e) => ({
                a: e.setPoint,
                b: e.activationDirection,
                c: e.status,
                d: e.mqttStatus,
                e: e.timestampCET,
                f: e.mode,
                g: e.response,
            }));
            const header = ['Set Point', 'Direction', 'Status', 'MQTT Status', 'Timestamp (CET)', 'Mode', 'Response'];
            DownloadAsExcel(data, 'Afrr Test Export', header);
        } else {
            toast.error('Wait for test completion');
        }
    };

    return (
        <div style={{ width: '45vw' }}>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={{
                        isPower: false,
                        profileTest: [
                            {
                                [fieldType.value]: '',
                                seconds: '',
                                power: '',
                            },
                        ],
                    }}
                    onSubmit={edgeData.market == 'aFRR' ? handleSubmitForAfrr : handleSubmit}
                    validationSchema={() => EdgeScheduleTest(fieldType.value)}
                >
                    {({ errors, touched, values, isValidating, ...props }) => {
                        return (
                            <Form>
                                <ModalComponent isOpen={saveTestModal} setOpen={setSaveTestModal}>
                                    <SaveProfileTest profileTest={values.profileTest} setSaveTestModal={setSaveTestModal} isPower={values.isPower} market={edgeData.market} />
                                </ModalComponent>
                                {changeScreen ? (
                                    <>
                                        <div className={bidclasses.Header}>
                                            <div className={allDeviceclasses.TableCount}>
                                                Status :
                                                <span>
                                                    <Typography size="14" content={curInterval ? 'Running' : 'Stopped'} />
                                                </span>
                                            </div>
                                            <div className={bidclasses.ButtonsContainer}>
                                                <DownloadButton onClick={handleDownloadAfrr} />
                                            </div>
                                        </div>
                                        <div style={{ maxHeight: '65vh', overflowY: 'scroll' }}>
                                            <Table
                                                head={['Set Point', 'Direction', 'Status', 'MQTT Status', 'Timestamp (CET)', 'Response']}
                                                keys={['setPoint', 'activationDirection', 'status', 'mqttStatus', 'timestampCET', 'response']}
                                                data={afrrTestLogs}
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className={edgeClasses.ScheduleTestInputContainer} style={{ alignItems: 'center' }}>
                                            <div className={classes.FieldControl2}>
                                                <label>Select Predefined Test</label>
                                                <Dropdown
                                                    name="test"
                                                    options={testOption}
                                                    onChange={(e) => {
                                                        props.setFieldValue('profileTest', e?.value);
                                                        props.setFieldValue('isPower', e?.isPower || false);
                                                        setSelectedTestOption(e);
                                                    }}
                                                />
                                            </div>
                                            {edgeData.market !== 'aFRR' && (
                                                <div className={classes.FieldControl2}>
                                                    <ToggleButton values={values} label={'Power'} name={`isPower`} />
                                                </div>
                                            )}
                                        </div>
                                        <div className={classes.FieldControl2} style={{ textAlign: 'center', marginTop: '2vh' }}>
                                            Or
                                        </div>
                                        <div style={{ maxHeight: '40vh', overflowY: 'auto' }}>
                                            <FieldArray name="profileTest">
                                                {({ push, remove, form }) => {
                                                    const { profileTest } = form.values;
                                                    return (
                                                        <div>
                                                            <div>
                                                                {profileTest?.map((item, index) => (
                                                                    <div className={edgeClasses.ScheduleTestInputContainer}>
                                                                        {values.isPower ? (
                                                                            <>
                                                                                <div key={index + fieldType.label} className={classes.FieldControl2}>
                                                                                    <label>
                                                                                        {fieldType.label}
                                                                                        <span className="required">*</span>
                                                                                    </label>
                                                                                    <Input name={`profileTest[${index}].[${fieldType.value}]`} type="number" />
                                                                                </div>

                                                                                <div key={index + 'Power'} className={classes.FieldControl2}>
                                                                                    <label>
                                                                                        Power <span className="required">*</span>
                                                                                    </label>
                                                                                    <Input name={`profileTest[${index}].power`} type="number" />
                                                                                </div>

                                                                                <div key={index + 'Seconds'} className={classes.FieldControl2}>
                                                                                    <label>
                                                                                        Seconds <span className="required">*</span>
                                                                                    </label>
                                                                                    <Input name={`profileTest[${index}].seconds`} type="number" />
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <div key={index + fieldType.label} className={classes.FieldControl2}>
                                                                                    <label>
                                                                                        {fieldType.label}
                                                                                        <span className="required">*</span>
                                                                                    </label>
                                                                                    <Input name={`profileTest[${index}].[${fieldType.value}]`} type="number" />
                                                                                </div>

                                                                                <div key={index + 'Seconds'} className={classes.FieldControl2}>
                                                                                    <label>
                                                                                        Seconds <span className="required">*</span>
                                                                                    </label>
                                                                                    <Input name={`profileTest[${index}].seconds`} type="number" />
                                                                                </div>
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className={classes.addInputActionWrapper}>
                                                                {profileTest.length > 1 && (
                                                                    <span onClick={() => remove(profileTest?.length - 1)}>
                                                                        <UilMinusCircle
                                                                            size={'1.5vw'}
                                                                            style={{
                                                                                color: 'var(--color-primary)',
                                                                                cursor: 'pointer',
                                                                                zIndex: '1',
                                                                            }}
                                                                        />
                                                                    </span>
                                                                )}
                                                                <span
                                                                    onClick={() =>
                                                                        push({
                                                                            [fieldType.value]: '',
                                                                            seconds: '',
                                                                        })
                                                                    }
                                                                >
                                                                    <UilPlusCircle
                                                                        size={'1.5vw'}
                                                                        style={{
                                                                            color: 'var(--color-primary)',
                                                                            cursor: 'pointer',
                                                                            zIndex: '1',
                                                                        }}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            </FieldArray>
                                        </div>
                                    </>
                                )}
                                <div className={edgeClasses.HistoryContainer}>
                                    {!changeScreen && (
                                        <div
                                            className={edgeClasses.HistoryLink}
                                            onClick={() => {
                                                setTestLogs({
                                                    status: true,
                                                    deviceId: edgeData.deviceId,
                                                });
                                                updateQueryParams('tab', 3);
                                                updateQueryParams(
                                                    'testLogs',
                                                    JSON.stringify({
                                                        status: true,
                                                        deviceId: edgeData.deviceId,
                                                    })
                                                );
                                                setSelectedIndex(4);
                                                setScheduleModal({ status: false, data: '' });
                                            }}
                                        >
                                            <p>History</p>
                                        </div>
                                    )}
                                    <div className={classes.ButtonContainer}>
                                        {changeScreen ? (
                                            <div>
                                                <button
                                                    type="button"
                                                    className="btn-secondary"
                                                    onClick={() => {
                                                        setChangeScreen(false);
                                                        setAfrrTestLogs([]);
                                                    }}
                                                    disabled={!!curInterval}
                                                >
                                                    Back
                                                </button>
                                            </div>
                                        ) : (
                                            <div>
                                                <button type="button" className="btn-secondary" onClick={() => setScheduleModal({ status: false, data: '' })}>
                                                    Close
                                                </button>
                                            </div>
                                        )}
                                        <div>
                                            <button type="button" className="btn-primary" onClick={() => setSaveTestModal({ status: true, id: '' })}>
                                                Save Test
                                            </button>
                                        </div>
                                        <div>
                                            {changeScreen ? (
                                                <button type="button" className="btn-primary" disabled={!curInterval} onClick={() => {
                                                    clearInterval(curInterval);
                                                    setCurInterval(null);
                                                    setCurrentIndex(0);
                                                }
                                                }>
                                                    Stop Test
                                                </button>
                                            ) : (
                                                <button type="submit" className="btn-primary">
                                                    Submit
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default Schedule;
