// Standard Library imports
import React, { useState, useEffect } from 'react';

// External library imports
import { toast } from 'react-toastify';
import { Formik, Form } from 'formik';
import { UilTrash, UilExternalLinkAlt } from '@iconscout/react-unicons';

// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import Table from '../../../../components/Table/Table';
import { useLoader } from '../../../../hooks';
import ModalComponent from '../../../../components/ModalComponent/ModalComponent';
import { signalsServiceForRoot } from '../../../../services/signalsServiceForRoot';
import { dataExportServiceForRoot } from '../../../../services/dataExportServicesForRoot';
import CustomTooltip from '../../../../components/CustomToolTip/CustomTooltip';
import DataExporterForm from '../ModalComponent/DataExporterForm';

// Css imports
import dataExportClasses from './index.module.css';
import classes from '../../../../styles/AllDevices.module.css';

const DataExporter = () => {
    const [startLoader, stopLoader] = useLoader();

    const [dataExportModal, setDataExportModal] = useState(false);
    const [dataSourceList, setDataSourceList] = useState([]);
    const [dataExportRequest, setDataExportRequest] = useState([]);

    useEffect(() => {
        fetchAlldataExportRequest();
    }, []);

    useEffect(() => {
        if (dataExportModal === true) {
            fetchDataSourceList();
        }
    }, [dataExportModal]);

    const fetchDataSourceList = () => {
        signalsServiceForRoot.readDataSourceList(
            () => startLoader('fetchDataSourceList'),
            handleDataSourceListSuccess,
            handleError,
            () => stopLoader('fetchDataSourceList')
        );
    };

    const fetchAlldataExportRequest = () => {
        dataExportServiceForRoot.getAllRequest(
            () => startLoader('fetDataExportRequest'),
            handleDataExportRequestSuccess,
            handleError,
            () => stopLoader('fetDataExportRequest')
        );
    };

    const handleDataSourceListSuccess = ({ data }) => {
        setDataSourceList(
            data.data.map((item) => ({
                label: item,
                value: item,
            }))
        );
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };

    const handleSuccess = (message) => {
        toast.success(message);
        fetchAlldataExportRequest();
        setDataExportModal(false);
    };

    const handleDeleteRequest = (id) => {
        dataExportServiceForRoot.deleteRequest(
            id,
            () => startLoader('deleteDataExportRequest'),
            () => handleSuccess('Data Export Request deleted successfully'),
            handleError,
            () => stopLoader('deleteDataExportRequest')
        );
    };

    const handleDataExportRequestSuccess = ({ data }) => {
        const proccessedData = data?.data?.map((item, index) => ({
            's.no': index + 1,
            requestId: item._id,
            collection: item.collectionName,
            query: item.query ? (
                <CustomTooltip content={JSON.stringify(item.query)}>
                    {JSON.stringify(item.query).substring(0, 35)}
                    {JSON.stringify(item.query).length > 34 && '...'}
                </CustomTooltip>
            ) : (
                '--'
            ),
            sort: item.sort ? (
                <CustomTooltip content={JSON.stringify(item.sort)}>
                    {JSON.stringify(item.sort).substring(0, 35)}
                    {JSON.stringify(item.sort).length > 34 && '...'}
                </CustomTooltip>
            ) : (
                '--'
            ),
            skip: item.skip ? item.skip : '--',
            limit: item.limit ? item.limit : '--',
            status: item.status,
            updatedAt: item.updatedAt,
            action: (
                <div className={dataExportClasses.ActionContainer}>
                    {item?.location && item?.status === 'success' && (
                        <CustomTooltip content={'View File'}>
                            <div className={dataExportClasses.Action} onClick={() => window.open(item?.location, '_blank')}>
                                <div>
                                    <UilExternalLinkAlt size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    )}
                    {item?.status === 'pending' && (
                        <CustomTooltip content={'Delete Request'}>
                            <div className={dataExportClasses.Action} onClick={() => handleDeleteRequest(item._id)}>
                                <div>
                                    <UilTrash size={'1.2vw'} style={{ color: 'var(--color-primary)' }} />
                                </div>
                            </div>
                        </CustomTooltip>
                    )}
                </div>
            ),
        }));
        setDataExportRequest(proccessedData);
    };

    return (
        <div>
            <div className={classes.AllDevices}>
                <ModalComponent isOpen={dataExportModal} setOpen={setDataExportModal} style={{ minWidth: '30vw', minHeight: '30vw' }}>
                    <Typography content="Create Export" />
                    <div className={classes.SupportNoteForm}>
                        <DataExporterForm fetchAlldataExportRequest={fetchAlldataExportRequest} setDataExportModal={setDataExportModal} dataSourceList={dataSourceList} />
                    </div>
                </ModalComponent>
                <div className={classes.Header}>
                    <div>
                        <Typography content="Data Exporter" />
                        <div className={classes.TableCount}>
                            Total Count :
                            <span>
                                <Typography size="14" content={dataExportRequest.length || 0} />
                            </span>
                        </div>
                        <div>
                            <button type="submit" className="btn-primary" style={{ marginTop: '0' }} onClick={() => setDataExportModal(true)}>
                                Create Export
                            </button>
                        </div>
                    </div>
                </div>
                <div className={dataExportClasses.TableContainer}>
                    <div style={{ width: '100%' }}>
                        <Table
                            head={['S.No', 'Request Id', 'Collection', 'Query', 'Sort', 'Skip', 'Limit', 'Status', 'Updated At', 'Action']}
                            keys={['s.no', 'requestId', 'collection', 'query', 'sort', 'skip', 'limit', 'status', 'updatedAt', 'action']}
                            data={dataExportRequest}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DataExporter;
