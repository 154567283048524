// Standard library imports
import React, { useEffect, useState } from 'react';
// Extenal libary imports
import { Form, Formik } from 'formik';
import { toast } from 'react-toastify';
import _ from 'lodash';
// Internal module imports
import Typography from '../../../../components/Typography/Typography';
import classes from '../../../../styles/BidModal.module.css';
import { Input } from '../../../../components/Inputs/Input';
import DatePicker from '../../../../components/Inputs/DatePicker/DatePicker';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import { useLoader } from '../../../../hooks';
import { generateHourlyTimestamps, momentTimeFormater } from '../../../../utils/dateHelper';
import { CreateBundleBidValidation } from '../../../../validations/BundleBids/BundleBidValidation';
import moment from 'moment';
import Dropdown from '../../../../components/Inputs/Dropdown';

const maintenanceSlotCss = {
    border: '1px solid #E25657',
    background: '#FFE3E3',
};

const bidTypes = [
    {
        label: 'Primary',
        value: 'primary',
    },
    {
        label: 'Secondary',
        value: 'secondary',
    },
];

const getInitialValues = (date, instances = [], capacity = {}, price = []) => {
    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const tempCapacity = {};
    const hourlyData = {
        price: {},
    };

    timestampsForWholeDay.forEach((hour, index) => {
        _.set(hourlyData, ['price', hour], _.get(price, index, 14));
    });

    instances.map((e) => {
        let temp = {};
        timestampsForWholeDay.forEach((hour, index) => {
            _.set(temp, [hour], _.get(capacity, [e.instanceId, index], 0.9));
        });
        tempCapacity[e.instanceId] = temp;
    });

    return {
        capacity: tempCapacity,
        price: hourlyData.price,
    };
};

const CreateBundleBid = ({ market, instanceBundle, customerId, setOpen, getBundleBids }) => {
    const defaultDateValue = momentTimeFormater(momentTimeFormater().format('YYYY-MM-DD'));
    const [startLoader, stopLoader] = useLoader();
    const [date, setDate] = useState(defaultDateValue);
    const [pairs, setPairs] = useState(instanceBundle.pairs || []);
    const [maintenanceSlots, setMaintenanceSlots] = useState([]);

    const [alreadyCreatedBids, setAlreadyCreatedBids] = useState([]);

    const defaultValues = getInitialValues(date, instanceBundle?.pairs);
    const [capacity, setCapacity] = useState({});
    const [price, setPrice] = useState([]);

    const [selectedBidType, setSelectedBidType] = useState(bidTypes[0]);

    const [initialValues, setInitialValues] = useState({
        price: defaultValues.price,
        capacity: defaultValues.capacity,
        reserveBidIdentification: '',
        bidType: selectedBidType.value,
    });

    useEffect(() => {
        BundleBiddingService.GetLastBid(instanceBundle.value, startLoader, handleLastBidSuccess, handleError, stopLoader);
    }, [selectedBidType]);

    const handleLastBidSuccess = ({ data }) => {
        let tempCap = {};
        const { reserveBidIdentification = '', date: lastBidDate } = data?.data?.bundleBid;

        _.get(data, ['data', 'data'], []).map((item) => {
            _.set(tempCap, [item.instanceId], _.get(item, [selectedBidType.value, 'capacity'], []));
        });
        const tempPrice = _.get(data, ['data', 'data', '0', selectedBidType.value, 'price'], []);
        setPrice(tempPrice);
        setCapacity(tempCap);
        setInitialValues({
            ...initialValues,
            reserveBidIdentification,
            ...getInitialValues(date, instanceBundle?.pairs, tempCap, tempPrice),
        });

        const tempDate = new Date(lastBidDate);
        tempDate.setDate(tempDate.getDate() + 1);
        setDate(tempDate);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    const handleSubmit = (val) => {
        const payload = {
            ...val,
            bidType: selectedBidType.value,
            date: momentTimeFormater(date).format('YYYY-MM-DD'),
            market,
            customerId: customerId,
            instanceBundleId: instanceBundle.value,
        };
        BundleBiddingService.CreateBundleBid(payload, startLoader, handleCreateBundleBidSuccess, handleError, stopLoader);
    };

    useEffect(() => {
        setInitialValues({ ...initialValues, ...getInitialValues(date, instanceBundle?.pairs, capacity, price) });
    }, [date]);

    const handleCreateBundleBidSuccess = ({ data }) => {
        if (data.data?.status === 'bids already present') {
            setAlreadyCreatedBids(data.data.instanceIds);
            toast.error('Bids are already created.');
        } else {
            toast.success('Bundle Bids created successfully');
            setOpen(false);
            getBundleBids();
        }
    };

    return (
        <div>
            <div>
                <Typography content={`${market} Increased Bidding`} size="16" />
                <div className={classes.Subtitle} style={{ margin: 0 }}>
                    Red-bordered hour slots signify customer-requested maintenance
                    <span className="required">*</span>
                </div>
                <div className={classes.FormContainer2}>
                    <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit} validationSchema={CreateBundleBidValidation(pairs, date)}>
                        {({ errors, touched, values, isValidating, ...props }) => {
                            return (
                                <Form>
                                    <div className={classes.InputContainer}>
                                        <div className={classes.FieldControl}>
                                            <label>
                                                Date <span className="required">*</span>
                                            </label>
                                            <div className="modal-date-picker">
                                                <DatePicker date={new Date(date)} setDate={(d) => setDate(momentTimeFormater(momentTimeFormater(d).format('YYYY-MM-DD')))} />
                                            </div>
                                        </div>
                                        {['FCR-D-INC', 'FCR-D-DEC'].includes(market) && (
                                            <div className={classes.FieldControl}>
                                                <label>
                                                    Bid Type <span className="required">*</span>
                                                </label>
                                                <div>
                                                    <Dropdown name="bidType" options={bidTypes} defaultValue={selectedBidType} onChange={setSelectedBidType} />
                                                </div>
                                            </div>
                                        )}
                                        <div>
                                            <div className={classes.FieldControl}>
                                                <label htmlFor="reserveBidIdentification">
                                                    Reserve Bid Identification
                                                    <span className="required">*</span>
                                                </label>
                                                <Input name="reserveBidIdentification" id="reserveBidIdentification" />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        {/*capacity  */}
                                        {pairs.map((el, i) => (
                                            <div key={i}>
                                                <div>
                                                    <div className={classes.Subtitle}>
                                                        Capacity(Mw) - {el.instanceName}{' '}
                                                        {alreadyCreatedBids.includes(el.instanceId) && <span className="required">Bid is already created.</span>}
                                                    </div>
                                                </div>

                                                {_.chunk(Object.keys(_.get(values, ['capacity', el.instanceId])), 6).map((chunk, chunkIndex) => (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexWrap: 'wrap',
                                                            gap: '1vw',
                                                        }}
                                                    >
                                                        {chunk.map((key, index) => (
                                                            <div>
                                                                <div className={classes.FieldControl}>
                                                                    <label>
                                                                        Slot {6 * chunkIndex + index + 1} {' / Hour '}
                                                                        {6 * chunkIndex + index}-{6 * chunkIndex + index + 1}
                                                                        <span className="required">*</span>
                                                                    </label>
                                                                    <Input
                                                                        name={`capacity.${el.instanceId}.${key}`}
                                                                        id={`capacity.${el.instanceId}.${key}`}
                                                                        type={'number'}
                                                                        style={maintenanceSlots.includes(6 * chunkIndex + index + 1) ? maintenanceSlotCss : {}}
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}

                                        {/*  */}

                                        <div>
                                            <div className={classes.Subtitle}>
                                                <span>Price(€)</span>
                                            </div>
                                            {_.chunk(Object.keys(values.price), 6).map((chunk, chunkIndex) => (
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        flexWrap: 'wrap',
                                                        gap: '1vw',
                                                    }}
                                                >
                                                    {chunk.map((key, index) => (
                                                        <div>
                                                            <div className={classes.FieldControl}>
                                                                <label>
                                                                    Slot {6 * chunkIndex + index + 1} {' / Hour '}
                                                                    {momentTimeFormater(key).hour()}-{momentTimeFormater(key).add(1, 'h').hour()}
                                                                    Price
                                                                    <span className="required">*</span>
                                                                </label>
                                                                <Input
                                                                    name={`price.${key}`}
                                                                    id={`price.${key}`}
                                                                    type={'number'}
                                                                    style={maintenanceSlots.includes(6 * chunkIndex + index + 1) ? maintenanceSlotCss : {}}
                                                                />
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                    <div className={classes.ButtonContainer}>
                                        <div>
                                            <button type="button" className="btn-secondary" onClick={() => setOpen(false)}>
                                                Cancel
                                            </button>
                                        </div>
                                        <div>
                                            <button type="submit" className="btn-primary">
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </div>
        </div>
    );
};

export default CreateBundleBid;
