// Standard Library imports
import React, { useState, useEffect } from 'react';

// Internal module imports

import SignalMonitoring from './SignalMonitoring';
import EdgeMonitoring from './EdgeMonitoring';
import TabsComponent from '../../../../components/Tabs/Tabs';
import { useLoader } from '../../../../hooks';
// External library imports
import { toast } from 'react-toastify';
import { AdminService } from '../../../../services/AdminService';

const Montiroting = ({ queryParamsData, updateQueryParams }) => {
    const [startLoader, stopLoader] = useLoader();

    const [selectedIndex, setSelectedIndex] = useState(queryParamsData.subTab ? queryParamsData.subTab : 0);
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        getAdmins();
    }, []);

    const getAdmins = () => {
        AdminService.ReadAll(startLoader, handleAdminSuccess, handleError, stopLoader);
    };

    const handleAdminSuccess = ({ data }) => {
        let temp = [
            {
                label: 'All',
                value: 'all',
            },
        ];
        data.data.map((e) => {
            temp.push({ label: e.name, value: e._id });
        });
        setAdmins(temp);
    };

    const handleError = (err) => {
        let data = err && err.response ? err.response.data : null;
        if (data) toast.error(data.message);
        else toast.error('Internal server error!');
    };
    let tabs = [
        {
            name: 'Signal Monitoring',
            component: (
                <>
                    <SignalMonitoring admins={admins} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
        {
            name: 'Edge Monitoring',
            component: (
                <>
                    <EdgeMonitoring admins={admins} queryParamsData={queryParamsData} updateQueryParams={updateQueryParams} />
                </>
            ),
            tabId: 2,
        },
    ];

    return (
        <div className={'ventilation'}>
            <TabsComponent
                selectedIndex={selectedIndex}
                tabs={tabs}
                onChangeTab={(index) => {
                    setSelectedIndex(index);
                    updateQueryParams('subTab', index);
                }}
            />
        </div>
    );
};

export default Montiroting;
