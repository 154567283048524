// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    marketEnabledGroups: 'admin/data',
    create: 'admin/bundle-bidding/instance-bundle',
    update: 'admin/bundle-bidding/instance-bundle',
    getBids: 'admin/bundle-bidding/instance-bundle',
    requestBid: 'admin/bundle-bidding/bid-request',
    createBundleBid: 'admin/bundle-bidding',
    bundleBids: 'admin/bundle-bidding',
    instanceDetails: 'admin/bundle-bidding/instance-details',
    updatePanicHours: 'admin/bundle-bidding/panic',
    lastBid: 'admin/bundle-bidding/last-bid',
    checkBidStatus: 'admin/bundle-bidding/check-bid-status',
    addResultId: 'admin/bundle-bidding/resultId',
    deleteBid: 'admin/bundle-bidding/delete-bids',
    updateDMinusOne: 'admin/bundle-bidding/d-1-bids',
    addNote: '/admin/bundle-bidding/add-note',
    getNotes: '/admin/bundle-bidding/get-note',
    deleteNote: '/admin/bundle-bidding/delete-note',
};

const CreateInstanceBundle = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.create, payload).then(callback).catch(error).finally(next);
};

const UpdateInstanceBundle = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetInstanceBundle = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getBids}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const GetMarketEnabledGroups = (market, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.marketEnabledGroups}/${market}/market-enabled-groups`).then(callback).catch(error).finally(next);
};

const GetBidRequest = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.requestBid}/${id}`, { params }).then(callback).catch(error).finally(next);
};

const CreateBundleBid = (payload, start, callback, error, next) => {
    start();
    return HttpClient.post(PATH.createBundleBid, payload).then(callback).catch(error).finally(next);
};

const GetBundleBids = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.bundleBids}`, { params }).then(callback).catch(error).finally(next);
};

const GetInstanceDetailsByIds = (params, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.instanceDetails}`, { params }).then(callback).catch(error).finally(next);
};

const UpdatePanicHours = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.updatePanicHours}/${id}`, payload).then(callback).catch(error).finally(next);
};
const GetLastBid = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.lastBid}/${id}`).then(callback).catch(error).finally(next);
};

const CheckBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.checkBidStatus}/${id}`).then(callback).catch(error).finally(next);
};

const AddResultId = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.addResultId}/${id}`, payload).then(callback).catch(error).finally(next);
};

const DeleteBundleBid = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteBid}/${id}`, { params }).then(callback).catch(error).finally(next);
};
const UpdateDMinusOne = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.updateDMinusOne}/${id}`, payload).then(callback).catch(error).finally(next);
};

const CreateNote = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.put(`${PATH.addNote}/${id}`, payload).then(callback).catch(error).finally(next);
};

const GetNote = (id, start, callback, error, next) => {
    start();
    return HttpClient.get(`${PATH.getNotes}/${id}`).then(callback).catch(error).finally(next);
};
const DeleteNote = (id, params, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.deleteNote}/${id}`, { params }).then(callback).catch(error).finally(next);
};

export const BundleBiddingService = {
    GetMarketEnabledGroups,
    CreateInstanceBundle,
    GetInstanceBundle,
    GetBidRequest,
    CreateBundleBid,
    GetBundleBids,
    GetInstanceDetailsByIds,
    UpdatePanicHours,
    GetLastBid,
    CheckBidStatus,
    AddResultId,
    DeleteBundleBid,
    UpdateDMinusOne,
    UpdateInstanceBundle,
    CreateNote,
    GetNote,
    DeleteNote,
};
