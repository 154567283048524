// External library imports
import * as yup from 'yup';
import _ from 'lodash';
// Internal library imports
import { ERRORS } from '../../utils/validationHelper';

export const instanceFormValidation = (defaultMetaKeys) => {
    const metaValidationObject = {};
    _.keys(defaultMetaKeys).forEach((item) => {
        metaValidationObject[item] = yup.string().required();
    });

    return yup.object().shape({
        name: yup.string().required(ERRORS.required),
        description: yup.string().optional(),
        licenseId: yup.string().required(ERRORS.required),
        market: yup.array().min(1, "This field can't be blank.").required("This field can't be blank."),
        metaErrorKeys: yup.object().shape(metaValidationObject),
        meta: yup.mixed(),
        inMarket: yup.boolean().required(),
        approvedCapacity: yup.string().optional(),
        webshopUrl: yup.string().optional(),
        address: yup.object().shape({
            floor: yup.string().optional().nullable(),
            streetName: yup.string().optional().nullable(),
            city: yup.string().optional().nullable(),
            pincode: yup.number().optional().nullable(),
            country: yup.string().optional().nullable(),
        }),
    });
};
