// Internal Module Imports
import { HttpClient } from '../utils/httpClient';

const PATH = {
    bidStatusUpdate: '/admin/data/bid-status',
};

const UpdateBidStatus = (id, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.bidStatusUpdate}/${id}`).then(callback).catch(error).finally(next);
};

export const BidService = {
    UpdateBidStatus,
};
