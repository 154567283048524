// External library imports
import * as yup from 'yup';
import moment from 'moment';
// Internal module import
import { ERRORS } from '../../utils/validationHelper';
import { generateHourlyTimestamps } from '../../utils/dateHelper';
import { momentTimeFormater } from '../../utils/timeHelper';

const validateNumber = () => yup.number().typeError(ERRORS.number).required(ERRORS.required);

const validateCapacityAndPrice = () =>
    yup.object().shape({
        capacity: validateNumber(),
        price: validateNumber(),
    });

export const resultMessageValidator = (isPrimaryBid = true) => {
    const wrapper = {
        reserveBidIdentification: yup.string().required(ERRORS.required),
        primaryResultMessageId: isPrimaryBid ? yup.number().required(ERRORS.required) : yup.number().optional(),
        secondaryResultMessageId: isPrimaryBid ? yup.number().optional() : yup.number().required(ERRORS.required),
    };
    return yup.object().shape(wrapper);
};

export const createBidValidationDynamically = (date, market) => {
    date = momentTimeFormater(momentTimeFormater(date).format('YYYY-MM-DD'));

    const timestampsForWholeDay = generateHourlyTimestamps(date, moment(date).add(1, 'day'));
    const hourlyDataValidation = {};
    timestampsForWholeDay.forEach(
        (hour) =>
            (hourlyDataValidation[hour] = yup.object().shape({
                capacity: validateNumber(),
                price: validateNumber(),
                ...(['aFRR-INC', 'aFRR-DEC'].includes(market)
                    ? {
                          regulationPrice: validateNumber(),
                      }
                    : {}),
            }))
    );
    return yup.object().shape({
        hourlyData: yup.object().shape(hourlyDataValidation),
        reserveBidIdentification: ['FCR-D-INC', 'FCR-D-DEC', 'FFR'].includes(market) ? yup.string().required(ERRORS.required) : yup.string(),
    });
};

export const createFcrBidValidation = yup.object().shape({
    slot1: validateCapacityAndPrice(),
    slot2: validateCapacityAndPrice(),
    slot3: validateCapacityAndPrice(),
    slot4: validateCapacityAndPrice(),
    slot5: validateCapacityAndPrice(),
    slot6: validateCapacityAndPrice(),
});

export const CreateSecondaryBidValidation = (instances) => {
    return yup.object().shape({
        capacity: yup.array().of(
            yup.mixed().test({
                name: 'isNotNull',
                message: ERRORS.required,
                test: (value) => {
                    if (value === null || value || value == 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
            })
        ),
        price: yup.array().of(
            yup.mixed().test({
                name: 'isNotNull',
                message: ERRORS.required,
                test: (value) => {
                    if (value === null || value >= 0) {
                        return true;
                    } else {
                        return false;
                    }
                },
            })
        ),
    });
};

export const notesValidation = () => {
    const wrapper = {
        description: yup.string().required(ERRORS.required),
    };
    return yup.object().shape(wrapper);
};
