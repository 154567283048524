// Standard library imports
import React, { useEffect, useState } from 'react';
// External library imports
import _ from 'lodash';
import { toast } from 'react-toastify';
// Internal module imports
import classes from '../../../../styles/CapacityContent.module.css';
import { useLoader } from '../../../../hooks';
import { BundleBiddingService } from '../../../../services/bundleBiddingService';
import Typography from '../../../../components/Typography/Typography';
import bidClasses from '../../index.module.css';

const CapacityContent = ({ bids = [], overallCapacity = [] }) => {
    const [startLoader, stopLoader] = useLoader();
    const [instanceIds, setInstanceIds] = useState(bids?.map((e) => e.instanceId));
    const [data, setData] = useState([]);

    const [capacity, setCapacity] = useState(() => {
        const capacityObj = {};
        bids.forEach((e) => {
            capacityObj[e.instanceId] = {
                overall: e.capacity,
                primary: _.get(e, ['primary', 'capacity'], []),
                secondary: _.get(e, ['secondary', 'capacity'], []),
            };
        });

        return capacityObj;
    });

    useEffect(() => {
        if (instanceIds.length) {
            getInstanceDetailsByIds();
        }
    }, []);

    const getInstanceDetailsByIds = () => {
        const params = {
            instanceIds,
        };
        BundleBiddingService.GetInstanceDetailsByIds(params, startLoader, handleInstanceSuccess, handleError, stopLoader);
    };

    const handleInstanceSuccess = ({ data }) => {
        const temp = data.data.map((item) => {
            return {
                name: item.name,
                capacity: {
                    primary: _.get(capacity, [item._id, 'primary']),
                    secondary: _.get(capacity, [item._id, 'secondary']),
                },
            };
        });
        setData(temp);
    };

    const handleError = (err) => {
        if (err && err.response) toast.error(err.response.data.message);
    };

    return (
        <div className={`${classes.CapacityContent} ${classes.BidInfoModal}`}>
            <div>
                <div className={bidClasses.InfoModalHeading}>
                    <Typography content={'Overall Capacity'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Capacity</th>
                                </tr>
                                {Array.from({ length: 8 }, (_, i) => i + n * 8).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{overallCapacity[key]}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>
            {data.map((item, index) => (
                <div key={index}>
                    <div className={bidClasses.InfoModalHeading}>
                        <Typography content={item.name} size={14} />
                    </div>
                    <div className={classes.Content}>
                        <div className={classes.ContainerStart}>
                            {[0, 1, 2].map((n) => (
                                <table>
                                    <tr>
                                        <th>Slot</th>
                                        <th>Hour</th>
                                        <th>Capacity (Primary/Secondary)</th>
                                    </tr>
                                    {Array.from({ length: 8 }, (_, i) => i + n * 8).map((key) =>
                                        key <= 23 ? (
                                            <tr>
                                                <td>{key + 1}</td>
                                                <td>
                                                    {key}-{key + 1}
                                                </td>
                                                <td>{`${_.get(item, ['capacity', 'primary', key], '--')} / ${_.get(item, ['capacity', 'secondary', key], '--')}`}</td>
                                            </tr>
                                        ) : null
                                    )}
                                </table>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CapacityContent;
