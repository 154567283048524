import { HttpClient } from '../utils/httpClient';

const PATH = {
    read_all: '/root/regulations',
    update: '/root/regulations',
    delete: '/root/regulations',
    readAllAdmin: '/admin/regulations',
    read_dataSourceList: '/root/dataSourceList',
    getMarketOverviewDetails: '/admin/regulations/market-overview',
};

const createRegulations = (payload, params, start, callback, error, next) => {
    start();
    return HttpClient.post(`${PATH.read_all}`, payload, { params: params }).then(callback).catch(error).finally(next);
};

const ReadAll = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_all).then(callback).catch(error).finally(next);
};

// deleting a regulation accepting id as param
const deleteRegulations = (id, start, callback, error, next) => {
    start();
    return HttpClient.delete(`${PATH.delete}/${id}`).then(callback).catch(error).finally(next);
};
// updating a regulation accepting id as param
const updateRegulation = (id, payload, start, callback, error, next) => {
    start();
    return HttpClient.patch(`${PATH.update}/${id}`, payload).then(callback).catch(error).finally(next);
};

const ReadAllAdmin = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.readAllAdmin).then(callback).catch(error).finally(next);
};

const readDataSourceList = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.read_dataSourceList).then(callback).catch(error).finally(next);
};

const GetMarketOverviewDetails = (start, callback, error, next) => {
    start();
    return HttpClient.get(PATH.getMarketOverviewDetails).then(callback).catch(error).finally(next);
};

export const regulationsService = {
    ReadAll,
    deleteRegulations,
    createRegulations,
    updateRegulation,
    readDataSourceList,
};

export const regulationsServiceAdmin = {
    RealAll: ReadAllAdmin,
    GetMarketOverviewDetails,
};
